import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { userContext } from 'context/userContext';
import { useScrollTo } from 'hooks/useScrollTo';
import Loader from 'components/loader/Loader';
import ErrorComponent from 'components/error/Error';
import Tabs from 'components/tabs/Tabs';
import CreatedCourses from './components/created/CreatedCourses';
import PurchasedCourses from './components/purchased/PurchasedCourses';
import Received from './components/certificates/Received';
import About from './components/about/About';
import TopSection from './components/TopSection';
import HorizontalCard from 'components/card/HorizontalCard';
import Bundles from './components/created/Bundles';
import classes from './Profile.module.scss';

export interface IProfile {
  tab:
    | 'about'
    | 'purchased-courses'
    | 'certificates'
    | 'created-courses'
    | 'published-courses'
    | 'bundles';
  subTab?:
    | 'main-info'
    | 'social-info'
    | 'activated'
    | 'not-activated'
    | 'certificate-templates'
    | 'received'
    | 'published'
    | 'approved'
    | 'submitted'
    | 'draft'
    | 'rejected';
}

const Profile = ({ tab, subTab }: IProfile) => {
  const location = useLocation();
  const {
    userData,
    userDataIsLoading,
    userDataError,
    newCertificates,
    lastWatchedCourse,
    showContinueWatching
  } = useContext(userContext);

  useScrollTo(
    [location.pathname],
    !!lastWatchedCourse &&
      !!showContinueWatching &&
      !location.pathname.includes('/user/about'),
    'tabs'
  );

  if (userDataIsLoading) return <Loader size="lg" hasText />;

  if (userDataError) return <ErrorComponent error={userDataError} />;

  const buildTabs = () => {
    const allTabs = [
      {
        id: 'about',
        name: 'About',
        route: 'user/about/main-info',
        component: () => <About data={userData} subTab={subTab} />
      },
      {
        id: 'purchased-courses',
        name: 'Purchased Courses',
        route: 'user/purchased-courses/activated',
        component: () => <PurchasedCourses subTab={subTab} />
      },
      {
        id: 'certificates',
        name: (
          <>
            {!!newCertificates.length && (
              <div className={classes['notification-circle']} />
            )}
            Certificates
          </>
        ),
        route: 'user/certificates',
        component: () => <Received />
      },
      {
        id: 'created-courses',
        name: 'Created Courses',
        route: 'user/created-courses/published',
        component: () => <CreatedCourses subTab={subTab} />
      },
      {
        id: 'bundles',
        name: 'Bundles',
        route: 'user/bundles',
        component: () => <Bundles />
      }
    ];

    // If logged in as admin and viewing your profile, only show main info tab
    if (userData.isAdmin) return allTabs.filter((tab) => tab.id === 'about');
    // Else show all tabs
    return allTabs;
  };

  return (
    <>
      <TopSection data={userData} />
      {!!lastWatchedCourse &&
        lastWatchedCourse?.progress < 100 &&
        showContinueWatching && (
          <div className={classes['continue-watching']}>
            <HorizontalCard course={lastWatchedCourse} withCloseBtn />
          </div>
        )}
      <Tabs
        tabs={buildTabs()}
        activeTabIndex={buildTabs().findIndex((t) => t.id === tab)}
        size={'sm'}
      />
    </>
  );
};

export default Profile;
