import { useEffect } from 'react';
import {
  useTable,
  usePagination,
  useRowSelect,
  Column,
  useFlexLayout
} from 'react-table';
import { IUser } from 'context/userContext';
import {
  ICourse,
  ICourseHistory,
  IPurchasedItem,
  IReport,
  ISinglePageData
} from 'query/course-module/dto';
import SelectedIsland from './SelectedIsland';
import Pagination from 'components/pagination/Pagination';
import classes from './Table.module.scss';

export interface ITable {
  columns: Column[];
  data:
    | ICourse[]
    | IUser[]
    | IPurchasedItem[]
    | ICourseHistory[]
    | IReport[]
    | ISinglePageData[];
  passSelectedRowsUp?: (rows: any) => void;
  islandProps?: {
    primBtnText: string;
    primBtnAction: (selectedRows: any) => void;
    secBtnText?: string;
    secBtnAction?: (selectedRows: any) => void;
    dynamicBtnText?: (selectedRows: any) => void;
  };
  paginationProps?: {
    activePage: number;
    setActivePage: (page: number) => void;
    pageSize: number;
    totalCount: number;
    siblingCount: number;
  };
  onClickRow?: (rowData: any) => void;
  rowHeight?: number;
  initialStateProp?: any;
}

const Table = ({
  columns,
  data,
  passSelectedRowsUp,
  islandProps,
  paginationProps,
  onClickRow,
  rowHeight,
  initialStateProp
}: ITable) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // pageCount,
    gotoPage,
    selectedFlatRows
  } = useTable(
    {
      columns,
      data,
      enableRowSelection: true,
      initialState: initialStateProp || {}
    },

    usePagination,
    useRowSelect,
    useFlexLayout
  );

  useEffect(() => {
    if (passSelectedRowsUp) {
      const originalRows = selectedFlatRows.map((flatRow) => flatRow.original);
      passSelectedRowsUp(originalRows);
    }
  }, [selectedFlatRows]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* We use the id to target element and render the dropdowns oustide of the overflow context */}
      <div id="table-wrapper" className={classes['table-wrapper']}>
        {!!selectedFlatRows.length && !!islandProps && (
          <SelectedIsland
            selectedFlatRows={selectedFlatRows}
            primBtnText={islandProps.primBtnText}
            primBtnAction={islandProps.primBtnAction}
            secBtnText={islandProps.secBtnText}
            secBtnAction={islandProps.secBtnAction}
            dynamicBtnText={islandProps.dynamicBtnText}
          />
        )}
        <table {...getTableProps()} id="table" className={classes['table']}>
          <thead id="table-head">
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps({
                          style: {
                            minWidth: column.minWidth,
                            width: column.width,
                            maxWidth: column.maxWidth
                          }
                        })}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()} id="table-body">
            {
              // Loop over the table rows
              rows.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    {...row.getRowProps()}
                    onClick={() => !!onClickRow && onClickRow(row.original)}
                    className={`
                      ${classes['row']} 
                      ${row.isSelected ? classes['selected'] : ''}
                      ${!!onClickRow ? classes['row--clickable'] : ''}
                    `}
                    style={{ height: `${rowHeight}px` }}
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.column.minWidth,
                                width: cell.column.width,
                                maxWidth: cell.column.maxWidth,
                                position: 'relative'
                              }
                            })}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        {!!paginationProps && (
          <Pagination
            currentPage={paginationProps.activePage}
            pageSize={paginationProps.pageSize}
            totalCount={paginationProps.totalCount}
            siblingCount={paginationProps.siblingCount}
            onPageChange={(page: number) => {
              gotoPage(page);
              paginationProps.setActivePage(page);
            }}
          />
        )}
      </div>
    </>
  );
};

export default Table;
