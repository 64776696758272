import React, { useContext, useMemo, useState } from 'react';
import {
  useNavigate,
  useLocation,
  createSearchParams,
  useParams
} from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import { ReactComponent as RowIcon } from 'assets/icons/table-row.svg';
import { ReactComponent as CardIcon } from 'assets/icons/table-card.svg';
import { appContext } from 'context/appContext';
import { userContext } from 'context/userContext';
import { windowContext } from 'context/windowsContext';
import Card from 'components/card/Card';
import Error from 'components/error/Error';
import NoResults from 'components/noResults/NoResults';
import Pagination from 'components/pagination/Pagination';
import Actions from '../profile/components/Actions';
import Button from 'components/buttons/Button';
import { IOption } from 'components/input/InputWrapper';
import Table from 'components/table/Table';
import CardSkeleton from 'components/skeleton/CardSkeleton';
import TableSkeleton from 'components/skeleton/TableSkeleton';
import CreateCourseEmptyCard, {
  createCourseEmptyRow
} from 'components/card/CreateCourseEmptyCard';
import {
  BrowseCoursesCols,
  ICourseRowWithEmptyCard
} from 'components/table/BrowseCoursesCols';
import CustomBreadcrumbs from 'components/breadcrumbs/CustomBreadcrumbs';
import SEO from 'components/SEO/SEO';
import {
  ICourseData,
  COURSE_CHANGES,
  ICourse,
  ICourseCategory,
  ICourseCategoryData,
  IFilterData,
  IFilterOptions,
  COURSE_SEGMENT
} from 'query/course-module/dto';
import {
  courseCategoriesDataQuery,
  courseSubcategoriesDataQuery,
  userCoursesDataQuery
} from 'query/course-module/queries';
import { isSafari } from 'utils/helpers';
import classes from './Courses.module.scss';

// TODO: Connect with the BE once it's ready
// const ratingFilter: IFilterData = {
//   filterName: 'Rating',
//   filterOptions: [
//     {
//       label: '5-stars',
//       value: COURSE_RATING.FIVE_STARS.toString()
//     },
//     {
//       label: '4-stars',
//       value: COURSE_RATING.FOUR_STARS.toString()
//     },
//     {
//       label: '3-stars',
//       value: COURSE_RATING.THREE_STARS.toString()
//     },
//     {
//       label: '2-stars',
//       value: COURSE_RATING.TWO_STARS.toString()
//     },
//     {
//       label: '1-star',
//       value: COURSE_RATING.ONE_STAR.toString()
//     }
//   ]
// };

const categoriesFilter: IFilterData = {
  filterName: 'Category',
  filterOptions: []
};

// const subcategoriesFilter: IFilterData = {
//   filterName: 'Subcategory',
//   filterOptions: []
// };

interface ICoursesProps {
  isCategoriesPage?: boolean;
  isSubcategoriesPage?: boolean;
}

const Courses = ({ isCategoriesPage, isSubcategoriesPage }: ICoursesProps) => {
  const { slug, subcategorySlug } = useParams();
  const { search: urlParams } = useLocation();
  const navigate = useNavigate();
  const {
    page: pageNumber,
    search,
    sort,
    price,
    category,
    segment
  } = queryString.parse(urlParams);
  const pageSize: number = 11;

  const { userData } = useContext(userContext);
  const { coursesView, setCoursesView, showFilter, updateFilter } =
    useContext(appContext);
  const { windowSize } = useContext(windowContext);
  const { isMdMobile } = windowSize;
  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);
  const [searchValue, setSearchValue] = useState<string>(
    (search as string) || ''
  );
  const [sortValue, setSortValue] = useState<string>((sort as string) || '');
  const [categoriesValue, setCategoriesValue] = useState<IOption[]>(
    categoriesFilter.filterOptions.filter((option: IFilterOptions) => {
      return category?.includes(option.value as string);
    })
  );

  const categoryName =
    (isCategoriesPage || isSubcategoriesPage) &&
    !!categoriesFilter.filterOptions.length &&
    categoriesFilter.filterOptions.filter(
      (category) => category.value === slug
    )[0]?.label;

  // const subcategoryName =
  //   isSubcategoriesPage &&
  //   !!subcategoriesFilter.filterOptions.length &&
  //   subcategoriesFilter.filterOptions.filter(
  //     (subcategory) => subcategory.value === subcategorySlug
  //   )[0]?.label;

  // const [subcategoriesValue, setSubcategoriesValue] = useState<IOption[]>(
  //   categoriesFilter.filterOptions.filter((option: IFilterOptions) => {
  //     return subcategory?.includes(option.value as string);
  //   })
  // );
  // const [filterValueRating, setFilterValueRating] = useState<IOption[]>(
  //   ratingFilter.filterOptions.filter((option: IFilterOptions) => {
  //     return rating?.includes(option.value as string);
  //   })
  // );

  const queryParams = `?&limit=${pageSize}&page=${page}${
    searchValue.length > 1 ? `&search[name]=${searchValue}` : ''
  }${sortValue.length > 1 ? `&sort=${sortValue}` : ''}&changes=${
    COURSE_CHANGES.NO_CHANGES
  }${!!price ? `&price=${price}` : ''}${
    isCategoriesPage || !!category
      ? `&category=${isCategoriesPage ? slug : category}`
      : ''
  }${!!segment ? `&segment=${segment}` : ''}`;

  useQuery<boolean, Error, ICourseCategoryData>({
    ...courseCategoriesDataQuery(),
    onSuccess: (fetchedCategories) => {
      categoriesFilter.filterOptions = fetchedCategories.result
        .filter((category) => category.counter > 0)
        .map((category) => ({
          label: category.name,
          value: category.slug
        }));
      setCategoriesValue(
        categoriesFilter.filterOptions.filter(
          (option: IFilterOptions) => category?.includes(option.value as string)
        )
      );
    }
  });

  // useQuery<boolean, Error, ICourseCategory[]>({
  //   ...courseSubcategoriesDataQuery(
  //     isCategoriesPage ? (slug as string) : (category as string)
  //   ),
  //   enabled: isCategoriesPage ? !!slug : !!category,
  //   onSuccess: (fetchedSubCategories) => {
  //     subcategoriesFilter.filterOptions = fetchedSubCategories.map(
  //       (subcat) => ({
  //         label: subcat.name,
  //         value: subcat.slug
  //       })
  //     );
  //     setSubcategoriesValue(
  //       subcategoriesFilter.filterOptions.filter(
  //         (option: IFilterOptions) =>
  //           subcategory?.includes(option.value as string)
  //       )
  //     );
  //   }
  // });

  const { isLoading, error, data } = useQuery<boolean, Error, ICourseData>({
    ...userCoursesDataQuery(queryParams),
    onSuccess: (data) => {
      if (!price) {
        if (!!data?.maxCourse && !!data.minCourse) {
          localStorage.setItem('minCoursePrice', data.minCourse.toString());
          localStorage.setItem('maxCoursePrice', data.maxCourse.toString());
        } else {
          localStorage.removeItem('minCoursePrice');
          localStorage.removeItem('maxCoursePrice');
        }
        const event = new CustomEvent('coursePricesUpdated');
        document.dispatchEvent(event);
      }
    }
  });
  const { result: coursesData, total_results } = data || {};
  const hasData = !!coursesData && !!coursesData.length;

  const cols = useMemo(
    () => BrowseCoursesCols({ isSelectable: false }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [coursesData]
  );

  const filterHandler = (value: IOption[], name: string) => {
    setPage(1);
    if (!name) {
      // setFilterValueRating([]);
      setCategoriesValue([]);
      // setSubcategoriesValue([]);
    }
    // if (name === 'Rating') {
    //   setFilterValueRating(value);
    // }
    if (name === 'Category') {
      setCategoriesValue(value);

      if (value.length === 0) {
        // setSubcategoriesValue([]);
      }
    }
    // if (name === 'Subcategory') {
    //   setSubcategoriesValue(value);
    // }
  };

  const renderBreadcrumbs = () => {
    let crumbs = [
      { name: 'Home', link: '/' },
      { name: 'Courses', link: '/courses' }
    ];
    if (!!categoryName)
      crumbs.push({ name: categoryName, link: `/category/${slug}` });
    // if (!!subcategoryName)
    //   crumbs.push({
    //     name: subcategoryName,
    //     link: `/category/${slug}/${subcategorySlug}`
    //   });

    return (
      <CustomBreadcrumbs className={classes['breadcrumbs']} crumbs={crumbs} />
    );
  };

  return (
    <>
      {isCategoriesPage && renderBreadcrumbs()}
      <div
        className={`
      ${classes['browse-courses']} 
      ${showFilter ? classes['browse-courses__grid'] : ''}`}
      >
        <SEO
          title={
            // !!subcategoryName
            //   ? `${subcategoryName} Courses`
            !!categoryName
              ? `${categoryName} Courses`
              : !!segment
              ? `${
                  Number(segment) === COURSE_SEGMENT.RECOMMENDED
                    ? 'Recommended for you'
                    : 'Most Popular'
                }`
              : 'Explore Courses'
          }
        />
        {!!showFilter && (
          <Actions
            isInverted
            hasSort
            sortValue={sortValue}
            passSortValueUp={setSortValue}
            hasFilter
            filters={
              isCategoriesPage
                ? []
                : [{ ...categoriesFilter, filterValue: categoriesValue }]
            }
            passFilterValueUp={filterHandler}
            onFilterClick={updateFilter}
            showFilterMenu={showFilter}
            setPage={setPage}
            hasPriceFilter
          />
        )}
        {(!isMdMobile || (isMdMobile && !showFilter)) && (
          <div
            className={`${classes['browse-courses__table-wrapper']}  
          ${isSafari ? classes['safari-support-overflow'] : ''}`}
          >
            <Actions
              isInverted
              hasSearch
              passSearchValueUp={setSearchValue}
              searchValue={searchValue}
              searchPlaceholder="Search Courses"
              hasSort={!showFilter}
              passSortValueUp={setSortValue}
              sortValue={sortValue}
              hasFilter={!showFilter}
              onFilterClick={updateFilter}
              setPage={setPage}
              isDebounced
            >
              {!isMdMobile && (
                <div className={classes['browse-courses__action-btns']}>
                  <Button
                    className={coursesView === 'cols' ? classes['active'] : ''}
                    variant="neutral"
                    icon={RowIcon}
                    onClick={() => setCoursesView('cols')}
                  />
                  <Button
                    className={coursesView === 'cards' ? classes['active'] : ''}
                    variant="neutral"
                    icon={CardIcon}
                    onClick={() => setCoursesView('cards')}
                  />
                </div>
              )}
            </Actions>
            <h1 className={`${classes['u-semiBold']} ${classes['u-h3']}`}>
              {isCategoriesPage
                ? categoryName
                : !!segment
                ? `${
                    Number(segment) === COURSE_SEGMENT.RECOMMENDED
                      ? 'Recommended for you'
                      : 'Most Popular Courses'
                  }`
                : 'Courses'}
            </h1>
            {isLoading &&
              (coursesView === 'cols' ? (
                <TableSkeleton
                  rows={pageSize + 1}
                  cols={cols.length}
                  isCoursesPage
                />
              ) : (
                <div
                  className={`${classes['cards']} ${classes['cards--four']}`}
                >
                  <CardSkeleton amount={pageSize + 1} variant="course" />
                </div>
              ))}
            {!!error && <Error error={error} />}
            {!isLoading && !error && hasData && (
              <>
                {coursesView === 'cols' && (
                  <Table
                    columns={cols}
                    data={[createCourseEmptyRow as unknown as ICourse].concat(
                      ...coursesData
                    )}
                    onClickRow={(row: ICourseRowWithEmptyCard) =>
                      navigate({
                        pathname: row?.isEmptyCard
                          ? `/courses/create`
                          : `/courses/${row.slug}`,
                        search: row?.isEmptyCard
                          ? ''
                          : `${createSearchParams({
                              changes: row.is_draft_copy
                                ? COURSE_CHANGES.ONLY_CHANGES
                                : COURSE_CHANGES.NO_CHANGES
                            })}`
                      })
                    }
                    paginationProps={{
                      activePage: page,
                      setActivePage: setPage,
                      pageSize: pageSize,
                      totalCount: total_results as number,
                      siblingCount: 1
                    }}
                  />
                )}
                {coursesView === 'cards' && (
                  <>
                    <div
                      className={`${classes['cards']} ${classes['cards--four']}`}
                    >
                      {!userData.isAdmin && <CreateCourseEmptyCard />}
                      {coursesData.map((course) => (
                        <Card
                          key={course._id}
                          onClick={() =>
                            navigate({
                              pathname: `/courses/${course.slug}`,
                              search: `${createSearchParams({
                                changes: course.is_draft_copy
                                  ? COURSE_CHANGES.ONLY_CHANGES
                                  : COURSE_CHANGES.NO_CHANGES
                              })}`
                            })
                          }
                          hasPrice
                          hasRating
                          course={course}
                          hideChangesIcon
                        />
                      ))}
                    </div>
                    <Pagination
                      currentPage={page}
                      totalCount={total_results as number}
                      siblingCount={1}
                      pageSize={pageSize}
                      onPageChange={(page) => setPage(page)}
                    />
                  </>
                )}
              </>
            )}
            {!isLoading && !error && !hasData && <NoResults />}
          </div>
        )}
      </div>
    </>
  );
};

export default Courses;
