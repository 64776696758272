import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import queryString from 'query-string';
import { ReactComponent as Chevron } from 'assets//icons/arrowShort.svg';
import { appContext } from 'context/appContext';
import { Web3Context } from 'context/web3Context';
import { toastContext } from 'context/toastContext';
import { popupContext } from 'context/popupContext';
import { useEthersSigner } from 'hooks/useEthersSigner';
import useCurrencyContract from 'hooks/useCurrencyContract';
import useComponentVisible from 'hooks/useComponentVisible';
import { ModalWrapper } from 'components/popup/common/ModalWrapper';
import Button from 'components/buttons/Button';
import Divider from 'components/divider/Divider';
import GenericPopup from 'components/popup/GenericPopup';
import InfoBox from 'components/info-box/InfoBox';
import Loader from 'components/loader/Loader';
import Badge from 'components/badge/Badge';
import { publishFeesQuery } from 'query/course-module/queries';
import {
  publishCourseMutation,
  rejectListMutation,
  rejectRepublishMutation,
  republishCourseMutation
} from 'query/course-module/mutations';
import { ICourse, IPublishFeesDto } from 'query/course-module/dto';
import {
  getContractInstance,
  payloadData,
  paymentContract
} from 'contracts/contracts';
import { submitTx } from 'utils/requests';
import {
  COURSE_STATUS,
  REACT_APP_BLOCK_EXPLORER_URL,
  REACT_APP_CURRENCY_CONTRACT_ADDRESS
} from 'utils/constants';
import { formatPrice, formatTokenDecimals } from 'utils/format';
import classes from './PublishPopup.module.scss';

interface IPublishPopupProps {
  data: ICourse;
}

const PublishPopup = ({ data }: IPublishPopupProps) => {
  const navigate = useNavigate();
  const { search: urlParams } = useLocation();
  const { changes } = queryString.parse(urlParams);
  const { setToast } = useContext(toastContext);
  const { clearPopup, setPopup } = useContext(popupContext);
  const { increasePendingTransactions, decreasePendingTransactions } =
    useContext(Web3Context);
  const {
    platformSettings: { platform_fee }
  } = useContext(appContext);
  const queryClient = useQueryClient();
  const { signer } = useEthersSigner();
  const { decimals, isFetching, balance, allowance, refetchAllowance } =
    useCurrencyContract(REACT_APP_CURRENCY_CONTRACT_ADDRESS);

  const price = +formatTokenDecimals(+data.price);
  const currency = data.currency.toLocaleUpperCase();
  const platformEarning = +formatTokenDecimals((platform_fee / 100) * price);
  const toBeRepublished =
    data.status === COURSE_STATUS.PUBLISHED ||
    data.status === COURSE_STATUS.PAUSED;

  const {
    isLoading: paymentDataIsLoading,
    error: paymentDataError,
    data: paymentData
  } = useQuery<boolean, Error, IPublishFeesDto>(publishFeesQuery(data._id));

  const isAllowanceSufficient = !!paymentData
    ? BigInt(allowance) >=
      BigInt(paymentData.additional_fee * Math.pow(10, decimals))
    : false;
  const isBalanceSufficient = !!paymentData
    ? BigInt(balance) >=
      BigInt(paymentData.additional_fee * Math.pow(10, decimals))
    : false;

  const {
    isLoading: approveIsLoading,
    // eslint-disable-next-line
    error: approveError,
    mutate: approveHandler
  } = useMutation({
    mutationKey: ['approve'],
    mutationFn: async () => {
      // Currency Contract Instance
      const contract = getContractInstance(
        'currency',
        signer,
        REACT_APP_CURRENCY_CONTRACT_ADDRESS
      );

      // Request max allowance
      const allowance = BigInt(Math.pow(2, 255));
      const txData = [paymentContract.address, allowance];

      increasePendingTransactions();
      await submitTx(contract, 'approve', txData);
    },
    onSuccess: () => {
      setToast({
        type: 'success',
        title: 'Successful Transaction',
        msg: 'Spending funds was approved.',
        position: 'top',
        autoClose: true
      });
      refetchAllowance();
      decreasePendingTransactions();
    },
    onError: (e: any) => {
      decreasePendingTransactions();
      setPopup(<GenericPopup type="error" msg={e.message} />);
    }
  });

  const {
    isLoading: publishIsLoading,
    // eslint-disable-next-line
    error: publishError,
    mutate: publishMutation
  } = useMutation({
    mutationFn: async () => {
      increasePendingTransactions();
      if (toBeRepublished) {
        const response = await republishCourseMutation(
          data._id,
          payloadData
        ).mutationFn();

        const contract = getContractInstance('payment', signer);

        // Convert price to 6 decimals (USDC)
        const price = BigInt(+data.price * Math.pow(10, decimals));
        response.updateItemData = {
          ...response.updateItemData,
          purchasePrice: price
        };
        // delete response.itemData.lister;

        const contractData = [
          response.item_id,
          response.updateItemData,
          response.paymentData,
          response.sigExpiry,
          response.signature
        ];

        const receipt = await submitTx(contract, 'updateItem', contractData);
        return receipt;
      } else {
        const response = await publishCourseMutation(
          data._id,
          payloadData
        ).mutationFn();

        const contract = getContractInstance('payment', signer);

        // Convert price to 6 decimals (USDC)
        const price = BigInt(+data.price * Math.pow(10, decimals));
        response.itemData = {
          ...response.itemData,
          purchasePrice: price
        };
        delete response.itemData.lister;

        const contractData = [
          response.itemData,
          response.paymentData,
          response.sigExpiry,
          response.signature
        ];
        const receipt = await submitTx(contract, 'list', contractData);
        return receipt;
      }
    },
    onSuccess: (receipt) => {
      const publish_cta = JSON.parse(
        localStorage.getItem('publish_cta') as string
      );
      delete publish_cta[data._id];
      localStorage.setItem('publish_cta', JSON.stringify(publish_cta));
      clearPopup();
      decreasePendingTransactions();
      setToast({
        type: 'success',
        title: 'Successful Transaction',
        msg: 'Your course is now publishing. View',
        linkMsg: 'transaction',
        linkTo: `${REACT_APP_BLOCK_EXPLORER_URL}/tx/${receipt.hash}`,
        position: 'top',
        autoClose: true
      });
      navigate('/user/created-courses/published');
      queryClient.invalidateQueries(['courses-data']);
      queryClient.invalidateQueries([
        'courses-data',
        { changes: changes, id: data._id }
      ]);
    },
    onError: (e: Error) => {
      if (e.message !== 'Another update is pending') {
        if (toBeRepublished) {
          rejectRepublishMutation(data._id).mutationFn();
        } else {
          rejectListMutation(data._id).mutationFn();
        }
      }
      decreasePendingTransactions();
      setPopup(
        <GenericPopup
          type="error"
          msg={e.message}
          buttonName="Close"
          buttonAction={clearPopup}
        />
      );
    }
  });

  return (
    <ModalWrapper size="lg">
      <div className={classes['wrapper']}>
        <h4 className={classes['u-semiBold']}>Publish Course</h4>
        <div className={classes['content']}>
          <div className={classes['content__header']}>
            <h3 className={classes['u-semiBold']}>{data.name}</h3>
            <div className={classes['content__header__badges']}>
              <Badge
                text={data.category.name || 'Category'}
                badgeType="primary"
                size="sm"
              />
              {/* {!!data?.subcategory?.name && (
                <Badge
                  text={data.subcategory.name}
                  variant="outline"
                  size="sm"
                />
              )} */}
            </div>
          </div>
          <div className={classes['content__body']}>
            <div className={classes['price-box']}>
              <div className={classes['price-box__row']}>
                <div className={classes['price-box__title']}>Course price</div>
                <div className={classes['price-box__value']}>
                  {`${formatPrice(price)} ${currency}`}
                </div>
              </div>
              <Divider dir="horizontal" />
              <div className={classes['price-box__row']}>
                <div className={classes['price-box__title']}>Platform fee</div>
                <div className={classes['price-box__value']}>
                  {`${platform_fee}%`}
                </div>
              </div>
              <div className={classes['price-box__row']}>
                <div className={classes['price-box__title']}>
                  Platform earning
                </div>
                <div className={classes['price-box__value']}>
                  {platformEarning.toLocaleString('en-US', {
                    maximumFractionDigits: 2
                  }) +
                    ' ' +
                    currency}
                </div>
              </div>
              <div className={classes['price-box__row']}>
                <div className={classes['price-box__title']}>
                  Creator earning
                </div>
                <div className={classes['price-box__value']}>
                  {(price - platformEarning).toLocaleString('en-US', {
                    maximumFractionDigits: 2
                  }) +
                    ' ' +
                    currency}
                </div>
              </div>
            </div>
            {paymentDataIsLoading ? (
              <Loader />
            ) : (
              !!paymentData &&
              !paymentDataError &&
              !!paymentData.additional_fee && (
                <PriceRow
                  course={data}
                  paymentData={paymentData}
                  currency={currency}
                />
              )
            )}
            <InfoBox
              type="info"
              msg="Please note, that publishing a course may take up to a few hours depending on the amount of videos uploaded, their size and the network traffic."
            />
            {!isBalanceSufficient && (
              <InfoBox
                type="warning"
                msg={
                  <>
                    You cannot purchase this course, because your USDC balance
                    is insufficient. We use{' '}
                    <a
                      href={
                        REACT_APP_BLOCK_EXPLORER_URL +
                        '/address/' +
                        REACT_APP_CURRENCY_CONTRACT_ADDRESS
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      this USDC token
                    </a>{' '}
                    on Polygon.
                  </>
                }
              />
            )}
            <div className={classes['btns-container']}>
              <Button variant="neutral" onClick={clearPopup} minWidth="lg">
                Cancel
              </Button>
              {isAllowanceSufficient && (
                <Button
                  onClick={() => publishMutation()}
                  isLoading={publishIsLoading}
                  variant="contrast"
                  minWidth="lg"
                  isDisabled={
                    isFetching || paymentDataIsLoading || !isBalanceSufficient
                  }
                  isWeb3
                >
                  {isBalanceSufficient
                    ? 'Publish Course'
                    : 'Insufficient Balance'}
                </Button>
              )}
              {!isAllowanceSufficient && (
                <Button
                  onClick={() => approveHandler()}
                  isLoading={approveIsLoading}
                  isWeb3
                  minWidth="lg"
                  isDisabled={isFetching || paymentDataIsLoading}
                >
                  Approve Spending
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default PublishPopup;

interface IPriceRowProps {
  course: ICourse;
  paymentData: IPublishFeesDto;
  currency: string;
}

const PriceRow = ({ course, paymentData, currency }: IPriceRowProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  return (
    <div ref={ref} className={classes['price-box']}>
      <div
        className={`${classes['price-box__row']} ${classes['u-cursor--pointer']}`}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      >
        <div className={classes['price-box__title']}>
          <Chevron
            className={`
              ${classes['chevron']}
              ${classes[`chevron--${isComponentVisible ? 'open' : 'closed'}`]}
             `}
          />
          <span>Total fees</span>
        </div>
        <div className={classes['price-box__value']}>
          {formatPrice(paymentData.additional_fee)} {currency}
        </div>
      </div>
      {isComponentVisible && (
        <>
          <Divider dir="horizontal" />
          <div className={classes['price-box__row']}>
            <div className={classes['price-box__title']}>Videos in course</div>
            <div className={classes['price-box__value']}>
              {course.video_count}
            </div>
          </div>
          <div className={classes['price-box__row']}>
            <div className={classes['price-box__title']}>Free videos left</div>
            <div className={classes['price-box__value']}>
              {paymentData.creator_uploaded_videos >
              paymentData.settings.free_video_count
                ? 0
                : paymentData.settings.free_video_count -
                  paymentData.creator_uploaded_videos}
            </div>
          </div>
          <div className={classes['price-box__row']}>
            <div className={classes['price-box__title']}>Videos to be paid</div>
            <div className={classes['price-box__value']}>
              {paymentData.additional_fee / paymentData.settings.video_fee}
            </div>
          </div>
          <div className={classes['price-box__row']}>
            <div className={classes['price-box__title']}>Fee per video</div>
            <div className={classes['price-box__value']}>
              {paymentData.settings.video_fee} {currency}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
