import { ProtectedLayout, UnprotectedLayout } from 'layouts';
import { Navigate, useRoutes, useParams } from 'react-router-dom';
import Landing from './landing';
import StyleGuide from './style-guide';
import Login from './auth/login';
import LoginForm from './auth/login/components/LoginForm';
import ForgotPassForm from './auth/login/components/ForgotPassForm';
import ResetPassword from './auth/reset-password';
import Register from './auth/register';
import VerifyReg from './auth/verify-reg';
import Page404 from './404';
import MainLayout from '../layouts/main';
import Dashboard from './dashboard';
import Profile from './dashboard/profile';
import PublicProfile from './dashboard/profile/public';
import EditProfile from './dashboard/profile/edit';
import EditSocial from './dashboard/profile/edit/EditSocial';
import Courses from './dashboard/courses';
import CreateCourse from './dashboard/courses/create';
import EditCourse from './dashboard/courses/edit';
import SingleCourse from './dashboard/courses/single';
import TemplateApp from './dashboard/templates';
import AdminDashboard from './dashboard/admin/dashboard/index';
import PermissionsGate from 'components/permissions-gate/PermissionsGate';
import { ROLE_FLAG } from 'context/userContext';
import AdminCourses from './dashboard/admin/courses';
import Legal from './legal';
import Results from './dashboard/search';
import Users from './dashboard/profile/users';
import UserFunds from './dashboard/funds';
import CourseReview from './dashboard/courses/single/review/CourseReview';
import AdminUsers from './dashboard/admin/users/AdminUsers';
import Categories from './dashboard/admin/courses/Categories';
import VerifyNewsletter from './auth/verify-newsletter';
import AdminFunds from './dashboard/admin/funds/AdminFunds';
import AdminStaticPages from './dashboard/admin/static-pages/AdminStaticPages';
import CreateCourseBundle from './dashboard/bundles/create';

const PublicRedirect = ({ uri }: { uri: string }) => {
  const { userId } = useParams();
  return <Navigate to={`/user/${userId}/${uri}`} replace={true} />;
};

export const routes = () => {
  return [
    {
      element: <UnprotectedLayout />,
      children: [
        {
          path: '/',
          element: (
            <MainLayout withPadding={false}>
              <Landing />
            </MainLayout>
          )
        },
        {
          path: '/privacy-policy',
          element: (
            <MainLayout>
              <Legal slug="privacy-policy" />
            </MainLayout>
          ),
          breadcrumb: null
        },
        {
          path: '/cookies-policy',
          element: (
            <MainLayout>
              <Legal slug="cookies-policy" />
            </MainLayout>
          ),
          breadcrumb: null
        },
        {
          path: '/terms-and-conditions',
          element: (
            <MainLayout>
              <Legal slug="terms-and-conditions" />
            </MainLayout>
          ),
          breadcrumb: null
        },
        {
          path: '/refund-policy',
          element: (
            <MainLayout>
              <Legal slug="refund-policy" />
            </MainLayout>
          ),
          breadcrumb: null
        },
        {
          path: '/content-guidelines',
          element: (
            <MainLayout>
              <Legal slug="content-guidelines" />
            </MainLayout>
          ),
          breadcrumb: null
        },
        {
          path: '/style-guide',
          element: (
            <MainLayout>
              <StyleGuide />
            </MainLayout>
          )
        },
        {
          path: '/login',
          element: (
            <Login>
              <LoginForm />
            </Login>
          )
        },
        {
          path: '/forgot-password',
          element: (
            <Login>
              <ForgotPassForm />
            </Login>
          )
        },
        {
          path: '/reset-password',
          element: (
            <MainLayout isSimple>
              <ResetPassword />
            </MainLayout>
          )
        },
        {
          path: '/register',
          element: (
            <MainLayout isSimple>
              <Register />
            </MainLayout>
          )
        },
        // Users
        {
          path: '/users',
          element: (
            <MainLayout>
              <Users />
            </MainLayout>
          )
        },
        {
          path: '/user/verify',
          element: (
            <MainLayout isSimple>
              <VerifyReg />
            </MainLayout>
          )
        },
        {
          path: '/user/newsletter/verify',
          element: (
            <MainLayout isSimple>
              <VerifyNewsletter />
            </MainLayout>
          )
        },

        // Public user profile
        {
          path: '/user/:slug',
          element: <PublicRedirect uri="about/main-info" />
        },
        // Public user profile - About
        {
          path: '/user/:slug/about',
          element: <PublicRedirect uri="about/main-info" />
        },
        {
          path: '/user/:slug/about/main-info',
          element: (
            <MainLayout>
              <PublicProfile tab="about" subTab="main-info" />
            </MainLayout>
          )
        },
        {
          path: '/user/:slug/about/social-info',
          element: (
            <MainLayout>
              <PublicProfile tab="about" subTab="social-info" />
            </MainLayout>
          )
        },
        // Public user profile - Published Courses
        {
          path: '/user/:slug/published-courses',
          element: (
            <MainLayout>
              <PublicProfile tab="published-courses" />
            </MainLayout>
          )
        },
        // Search results page (Courses + Content creators)
        {
          path: '/results',
          element: (
            <MainLayout>
              <Results />
            </MainLayout>
          )
        },
        // Courses
        {
          path: '/courses',
          element: (
            <MainLayout>
              <Courses />
            </MainLayout>
          )
        },
        {
          path: '/courses/:slug',
          element: (
            <MainLayout>
              <SingleCourse tab="videos" />
            </MainLayout>
          )
        },
        {
          path: '/courses/:slug/test-questions',
          element: (
            <MainLayout>
              <SingleCourse tab="test-questions" />
            </MainLayout>
          ),
          breadcrumb: null
        },
        {
          path: '/courses/:slug/documents',
          element: (
            <MainLayout>
              <SingleCourse tab="documents" />
            </MainLayout>
          ),
          breadcrumb: null
        },
        {
          path: '/courses/:slug/certificate',
          element: (
            <MainLayout>
              <SingleCourse tab="certificate" />
            </MainLayout>
          ),
          breadcrumb: null
        },
        {
          path: '/courses/:slug/reviews',
          element: (
            <MainLayout>
              <SingleCourse tab="reviews" />
            </MainLayout>
          ),
          breadcrumb: null
        },
        // Categories
        {
          path: '/category/:slug',
          element: (
            <MainLayout>
              <Courses isCategoriesPage />
            </MainLayout>
          ),
          breadcrumb: null
        },
        // Categories
        {
          path: '/category/:slug/:subcategorySlug',
          element: (
            <MainLayout>
              <Courses isCategoriesPage isSubcategoriesPage />
            </MainLayout>
          ),
          breadcrumb: null
        }
      ]
    },
    {
      element: <ProtectedLayout />,
      children: [
        {
          element: <Dashboard />,
          children: [
            // User profile
            {
              path: '/user',
              element: <Navigate to="/user/about/main-info" replace={true} />
            },
            // User profile - About
            {
              path: '/user/about',
              element: <Navigate to="/user/about/main-info" replace={true} />
            },
            {
              path: '/user/about/main-info',
              element: <Profile tab="about" subTab="main-info" />
            },
            {
              path: '/user/about/main-info/edit',
              element: <EditProfile />
            },
            {
              path: '/user/about/social-info',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <Profile tab="about" subTab="social-info" />
                </PermissionsGate>
              )
            },
            {
              path: '/user/about/social-info/edit',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <EditSocial />
                </PermissionsGate>
              )
            },
            // User profile - Purchased courses
            {
              path: '/user/purchased-courses',
              element: (
                <Navigate
                  to="/user/purchased-courses/activated"
                  replace={true}
                />
              )
            },
            {
              path: '/user/purchased-courses/activated',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <Profile tab="purchased-courses" subTab="activated" />
                </PermissionsGate>
              )
            },
            {
              path: '/user/purchased-courses/not-activated',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <Profile tab="purchased-courses" subTab="not-activated" />
                </PermissionsGate>
              )
            },
            // User profile - Certificates
            {
              path: '/user/certificates',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <Profile tab="certificates" subTab="received" />
                </PermissionsGate>
              )
            },
            {
              path: '/user/bundles',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <Profile tab="bundles" subTab="received" />
                </PermissionsGate>
              )
            },
            // User profile - Created courses
            {
              path: '/user/created-courses',
              element: (
                <Navigate to="/user/created-courses/published" replace={true} />
              )
            },
            {
              path: '/user/created-courses/published',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <Profile tab="created-courses" subTab="published" />
                </PermissionsGate>
              )
            },
            {
              path: '/user/created-courses/approved',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <Profile tab="created-courses" subTab="approved" />
                </PermissionsGate>
              )
            },
            {
              path: '/user/created-courses/submitted',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <Profile tab="created-courses" subTab="submitted" />
                </PermissionsGate>
              )
            },
            {
              path: '/user/created-courses/draft',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <Profile tab="created-courses" subTab="draft" />
                </PermissionsGate>
              )
            },
            {
              path: '/user/created-courses/rejected',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <Profile tab="created-courses" subTab="rejected" />
                </PermissionsGate>
              )
            },
            {
              path: '/user/created-courses/certificates',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <Profile
                    tab="created-courses"
                    subTab="certificate-templates"
                  />
                </PermissionsGate>
              )
            },
            {
              path: '/user/sales',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <UserFunds tab="claim" />
                </PermissionsGate>
              )
            },
            {
              path: '/user/purchases',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <UserFunds tab="transactions" />
                </PermissionsGate>
              )
            },
            {
              path: '/user/report',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <UserFunds tab="report" />
                </PermissionsGate>
              )
            },
            // Bundles
            {
              path: '/bundle/create',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <CreateCourseBundle />
                </PermissionsGate>
              )
            },
            // Courses
            {
              path: '/courses/create',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <CreateCourse />
                </PermissionsGate>
              )
            },
            {
              path: '/courses/:slug/edit',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <EditCourse />
                </PermissionsGate>
              )
            },
            // Video pages
            {
              path: '/courses/:slug/:videoId',
              element: <SingleCourse tab="videos" isVideoPage />
            },
            {
              path: '/courses/:slug/:videoId/test-questions',
              element: <SingleCourse tab="test-questions" isVideoPage />,
              breadcrumb: null
            },
            {
              path: '/courses/:slug/:videoId/documents',
              element: <SingleCourse tab="documents" isVideoPage />,
              breadcrumb: null
            },
            {
              path: '/courses/:slug/:videoId/certificate',
              element: <SingleCourse tab="certificate" isVideoPage />,
              breadcrumb: null
            },
            {
              path: '/courses/:slug/:videoId/reviews',
              element: <SingleCourse tab="reviews" isVideoPage />,
              breadcrumb: null
            },
            // Templates
            {
              path: '/templates/create',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <TemplateApp />
                </PermissionsGate>
              )
            },
            {
              path: '/templates/:id/edit',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.USER} redirect>
                  <TemplateApp isEdit />
                </PermissionsGate>
              )
            },
            // Admin
            {
              path: '/admin',
              element: <Navigate to="/admin/dashboard" replace />
            },
            {
              path: '/admin/dashboard',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.ADMIN} redirect>
                  <AdminDashboard />
                </PermissionsGate>
              )
            },
            {
              path: '/admin/courses',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.ADMIN} redirect>
                  <AdminCourses tab="published" />
                </PermissionsGate>
              )
            },
            {
              path: '/admin/courses/submitted',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.ADMIN} redirect>
                  <AdminCourses tab="submitted" />
                </PermissionsGate>
              )
            },
            {
              path: '/admin/courses/drafts',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.ADMIN} redirect>
                  <AdminCourses tab="drafts" />
                </PermissionsGate>
              )
            },
            {
              path: '/admin/courses/:slug',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.ADMIN} redirect>
                  <SingleCourse isAdminPage tab="videos" />
                </PermissionsGate>
              )
            },
            {
              path: '/admin/courses/:slug/test-questions',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.ADMIN} redirect>
                  <SingleCourse isAdminPage tab="test-questions" />
                </PermissionsGate>
              ),
              breadcrumb: null
            },
            {
              path: '/admin/courses/:slug/documents',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.ADMIN} redirect>
                  <SingleCourse isAdminPage tab="documents" />
                </PermissionsGate>
              ),
              breadcrumb: null
            },
            {
              path: '/admin/courses/:slug/certificate',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.ADMIN} redirect>
                  <SingleCourse isAdminPage tab="certificate" />
                </PermissionsGate>
              ),
              breadcrumb: null
            },
            {
              path: '/admin/courses/:slug/history',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.ADMIN} redirect>
                  <SingleCourse isAdminPage tab="history" />
                </PermissionsGate>
              ),
              breadcrumb: null
            },
            {
              path: '/admin/courses/:slug/reviews',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.ADMIN} redirect>
                  <SingleCourse isAdminPage tab="reviews" />
                </PermissionsGate>
              ),
              breadcrumb: null
            },
            {
              path: '/admin/courses/:slug/review',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.ADMIN} redirect>
                  <CourseReview />
                </PermissionsGate>
              ),
              breadcrumb: 'Course Review'
            },
            {
              path: '/admin/categories',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.ADMIN} redirect>
                  <Categories />
                </PermissionsGate>
              )
            },
            {
              path: '/admin/courses/highlighted',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.ADMIN} redirect>
                  <AdminCourses tab="highlighted" />
                </PermissionsGate>
              ),
              breadcrumb: null
            },
            {
              path: '/admin/transactions',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.ADMIN} redirect>
                  <AdminFunds tab="transactions" />
                </PermissionsGate>
              )
            },
            {
              path: '/admin/base',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.ADMIN} redirect>
                  <AdminFunds tab="base" />
                </PermissionsGate>
              )
            },
            {
              path: '/admin/tax',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.ADMIN} redirect>
                  <AdminFunds tab="tax" />
                </PermissionsGate>
              )
            },
            {
              path: '/admin/currencies',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.ADMIN} redirect>
                  <AdminFunds tab="currencies" />
                </PermissionsGate>
              )
            },
            {
              path: '/admin/users',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.ADMIN} redirect>
                  <AdminUsers />
                </PermissionsGate>
              )
            },
            {
              path: '/admin/static-pages',
              element: (
                <PermissionsGate role_flag={ROLE_FLAG.ADMIN} redirect>
                  <AdminStaticPages />
                </PermissionsGate>
              )
            }
          ]
        }
      ]
    },
    {
      path: '/404',
      element: <Page404 />,
      breadcrumb: null
    },
    {
      path: '*',
      element: <Navigate to="/404" replace={true} />
    }
  ];
};

export function Router() {
  const routing = useRoutes(routes());

  return routing;
}
