import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { InputWrapper } from 'components/input/InputWrapper';
import ReactSelectWrapper from 'components/select/ReactSelectWrapper';
import { courseCategoriesDataQuery } from 'query/course-module/queries';
import { ICourseCategoryData, ICourseCategory } from 'query/course-module/dto';
import { InputMedia } from 'components/input/InputMedia';
import { IFormik } from './CourseBundleForm';
import { handleMediaUpload } from 'utils/processing';
import classes from './Form.module.scss';

interface IStep3
  extends Pick<
    IFormik,
    | 'values'
    | 'errors'
    | 'touched'
    | 'setFieldValue'
    | 'setFieldTouched'
    | 'setFieldError'
    | 'handleBlur'
  > {}

export const Step3 = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  handleBlur
}: IStep3) => {
  const { isLoading, error, data } = useQuery<
    boolean,
    Error,
    ICourseCategoryData
  >(courseCategoriesDataQuery());

  useEffect(() => {
    if (!!error) setFieldError('category', error.message);
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildOptions = (options: ICourseCategory[]) => {
    return options.map((category) => ({
      label: category.name,
      value: category._id
    }));
  };

  return (
    <div className={classes['bundle-form-step']}>
      <InputWrapper
        name="name"
        type="text"
        variant="outline"
        onChange={(e) => setFieldValue('name', e)}
        onBlur={(e) => handleBlur(e)}
        value={values.name}
        title="Bundle Name"
        placeholder="Enter Bundle Name"
        isRequired
        error={errors.name && touched.name ? errors.name : ''}
        limit={60}
        subtitle={`${values.name?.length} of 60 characters used`}
      />
      <ReactSelectWrapper
        name="category"
        title="Bundle Category"
        placeholder="Select Category"
        isRequired
        hasTooltip
        tooltipText="Selecting a bundle category will make it easier for you bundle to be found."
        options={data ? buildOptions(data.result) : []}
        isLoading={isLoading}
        isSearchable
        onChange={(e) => {
          setFieldValue('category', e);
          setFieldValue('subcategory', null);
        }}
        onBlur={(e) => handleBlur(e)}
        error={
          errors.category && touched.category ? (errors.category as string) : ''
        }
        value={values.category}
        isClearable
      />
      <InputWrapper
        name="description"
        type="textarea"
        variant="outline"
        onChange={(e) => setFieldValue('description', e)}
        onBlur={(e) => handleBlur(e)}
        value={values.description}
        title="Bundle Description"
        isRequired
        placeholder="Enter Bundle Description"
        hasTooltip
        tooltipText="A longer description of your Bundle. This description will be visible on your Bundle detailed page."
        error={
          errors.description && touched.description ? errors.description : ''
        }
        subtitle={`${values.description?.length} of 500 characters used`}
      />
      <InputMedia
        name="thumbnail"
        title="Bundle Thumbnail"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleMediaUpload(
            e,
            'thumbnail',
            setFieldValue,
            setFieldTouched,
            setFieldError
          );
        }}
        onClear={async () => {
          setFieldValue('thumbnail', '');
        }}
        hasTooltip
        tooltipText={
          'Upload Bundle Thumbnail image. It will be visible in the Bundle Cards on the explore page.'
        }
        isRequired
        pic={values.thumbnail}
        error={errors.thumbnail && touched.thumbnail ? errors.thumbnail : ''}
      />
    </div>
  );
};

export default Step3;
