import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import NoCourses from 'assets/images/no-courses.svg';
import Card from 'components/card/Card';
import ErrorComponent from 'components/error/Error';
import EmptyState from 'components/emptyState/EmptyState';
import NoResults from 'components/noResults/NoResults';
import Pagination from 'components/pagination/Pagination';
import CardSkeleton from 'components/skeleton/CardSkeleton';
import { userBundlesQuery } from 'query/course-module/queries';
import { ICourseBundleDto } from 'query/course-module/dto';
import { userContext } from 'context/userContext';
import classes from './Bundles.module.scss';

const Bundles = () => {
  const navigate = useNavigate();
  const { search: urlParams } = useLocation();
  const { userData } = useContext(userContext);
  const { page: pageNumber } = queryString.parse(urlParams);
  const pageSize: number = 9;
  const [page, setPage] = useState<number>(parseInt(pageNumber as string) || 1);
  const queryParams = `?&limit=${pageSize}&page=${page}&creator_id=${userData._id}`;

  const { isLoading, error, data } = useQuery<boolean, Error, ICourseBundleDto>(
    userBundlesQuery(queryParams)
  );

  const { result, total_results } = data || {};

  const hasData = !!result && result?.length > 0;

  return (
    <div className={classes['wrapper']}>
      {isLoading && (
        <div className={`${classes['cards']} ${classes['cards--three']}`}>
          <CardSkeleton amount={3} variant="course" />
        </div>
      )}
      {error && <ErrorComponent error={error} />}
      {hasData && !isLoading && (
        <>
          <div className={`${classes['cards']} ${classes['cards--three']}`}>
            {(result as any).map((bundle: any) => {
              return (
                <Card
                  key={bundle._id}
                  hasPrice
                  course={bundle}
                  // onClick={() => navigate(`/bundles/${bundle._id}`)}
                />
              );
            })}
          </div>
          <Pagination
            currentPage={page}
            totalCount={total_results as number}
            siblingCount={1}
            pageSize={pageSize}
            onPageChange={(page) => setPage(page)}
          />
        </>
      )}
      {!hasData && !isLoading && !error && <NoResults />}
      {!hasData && !isLoading && !error && (
        <EmptyState
          icon={NoCourses}
          title={'There are no Bundles at the moment.'}
          message={
            'Create your first course. Add videos and create NFT certificate.'
          }
          buttonName={'Create Bundle'}
          onClick={() => navigate('/courses/create/bundle')}
        />
      )}
    </div>
  );
};

export default Bundles;
