import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import DragAndDropCard from 'components/drag-and-drop/DragАndDropCard';
import { ICourse } from 'query/course-module/dto';
import SelectedCourseCard from 'components/card/SelectedCourseCard';
import { windowContext } from 'context/windowsContext';
import classes from './SelectedCoursesDnD.module.scss';

interface IDragAndDropCourses {
  data: ICourse[];
  onOrderChange: (data: ICourse[]) => void;
}

const DragAndDropCourses = ({ data, onOrderChange }: IDragAndDropCourses) => {
  const {
    windowSize: { isLgMobile }
  } = useContext(windowContext);

  const updateDataIndex = (data: ICourse) => {
    return { ...data };
  };

  const isMounted = useRef(false);
  const [cards, setCards] = useState(data.map(updateDataIndex));

  // Drag&Drop move card functionality
  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      })
    );
  }, []);

  // Trigger course order change mutation only on drop
  const onDrop = () => onOrderChange(cards);

  // Update course cards on remove from highlighted
  useEffect(() => {
    if (data.length !== cards.length) setCards(data.map(updateDataIndex));
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // NOTE: avoid automatically mutating courses order on initial render on local environment
    isMounted.current = true;
  }, []);

  // Update cards on data change
  useEffect(() => {
    if (isMounted.current) {
      setCards(data);
    }
  }, [data]);

  return (
    <div className={classes['dnd']}>
      {!isLgMobile && (
        <div className={classes['header']}>
          <div>Course Name</div>
          <div>Creator</div>
          <div>Price</div>
          <div>Videos</div>
          <div>Expires</div>
        </div>
      )}
      <DndProvider backend={HTML5Backend}>
        {cards.map((card, i) => (
          <DragAndDropCard
            key={card._id}
            card={card}
            index={i}
            moveCard={moveCard}
            onDrop={onDrop}
            canDrag={true}
          >
            <SelectedCourseCard key={card._id} course={card} size="sm" />
          </DragAndDropCard>
        ))}
      </DndProvider>
    </div>
  );
};

export default DragAndDropCourses;
