import { IFormik } from './CourseBundleForm';
import DragAndDropCourses from './SelectedCoursesDnD';

interface IStep2
  extends Pick<
    IFormik,
    | 'values'
    | 'errors'
    | 'touched'
    | 'setFieldValue'
    | 'setFieldTouched'
    | 'setFieldError'
  > {}

export const Step2 = ({ values, setFieldValue }: IStep2) => {
  return (
    <DragAndDropCourses
      data={values.courses}
      onOrderChange={(courses) => setFieldValue('courses', courses as any)}
    />
  );
};

export default IStep2;
