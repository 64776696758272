import classes from './TableSkeleton.module.scss';

interface ICardSkeleton {
  rows: number;
  cols: number;
  size: 'sm' | 'md';
  isCoursesPage?: boolean;
}

const TableSkeleton = ({ rows, cols, size, isCoursesPage }: ICardSkeleton) => {
  // Create an array of div elements based on the amount prop
  const skeleton = Array.from({ length: rows }, (_, i) => (
    <div
      key={i}
      className={`${classes['table__row']} ${classes[`table__row--${size}`]}`}
    >
      {isCoursesPage && (
        <div className={classes['table__row__img-wrapper']}>
          <div className={classes['table__row__img-wrapper__img']} />
          <div className={classes['table__row__img-wrapper__badge']}>
            <div />
            <div />
          </div>
        </div>
      )}
      {Array.from({ length: isCoursesPage ? cols - 1 : cols }, (_, i) => (
        <div key={i} className={classes['table__row__badge']}>
          <div className={classes['table__row__badge__label']} />
        </div>
      ))}
    </div>
  ));

  return (
    <div className={classes['table']}>
      <div className={classes['table__header__row']}>
        {Array.from({ length: cols }, (_, i) => (
          <div
            key={i}
            className={
              classes[
                `table__header__row__badge${
                  isCoursesPage && i === 0 ? '-image' : ''
                }`
              ]
            }
          >
            <div className={classes['table__header__row__badge__label']} />
          </div>
        ))}
      </div>
      {skeleton}
    </div>
  );
};

TableSkeleton.defaultProps = {
  rows: 1,
  size: 'md',
  cols: 4
};

export default TableSkeleton;
