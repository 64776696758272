import moment from 'moment';
import { Column } from 'react-table';
import { ReactComponent as Image } from 'assets/icons/image.svg';
import TextWithCharLimit from 'components/textWithCharLimit/TextWithCharLimit';
import Badge from 'components/badge/Badge';
import { ICourse } from 'query/course-module/dto';
import { REACT_APP_FILES_EDU_PUBLIC_DOMAIN } from 'utils/constants';
import { dateFormatUrl } from 'utils/static';
import { formatNames, formatPrice } from 'utils/format';
import { tableColumnsWidths } from './utils/table-utils';
import classes from './BrowseCoursesCols.module.scss';
import { IndeterminateCheckbox } from './InterdeterminateCheckbox';

export interface ICourseRowWithEmptyCard extends ICourse {
  isEmptyCard?: boolean;
}

interface IBrowseCoursesCols {
  isSelectable?: boolean;
  isBundlePage?: boolean;
}

export const BrowseCoursesCols = ({
  isSelectable,
  isBundlePage
}: IBrowseCoursesCols) => {
  const cols: Column[] = [
    {
      Header: 'Course Name',
      accessor: 'course_name',
      maxWidth: tableColumnsWidths.courseNameAndPicMax,
      minWidth: tableColumnsWidths.courseNameAndPicMin,
      width: tableColumnsWidths.courseNameAndPic,
      Cell: ({ row }: { row: { original: ICourseRowWithEmptyCard } }) => {
        return (
          <div className={classes['course']}>
            <div
              className={`${classes['course__img-container']} ${
                !row.original.thumbnail
                  ? classes['course__img-container--empty']
                  : ''
              }`}
            >
              {!!row.original.thumbnail && (
                <img
                  src={
                    row.original.isEmptyCard
                      ? row.original.thumbnail
                      : REACT_APP_FILES_EDU_PUBLIC_DOMAIN +
                        row.original.thumbnail
                  }
                  alt={`thumb-${row.original.name}`}
                />
              )}
              {!row.original.thumbnail && <Image />}
            </div>
            <div
              className={`${classes['u-semiBold']} ${classes['u-body1']} ${classes['course__name']}`}
            >
              <TextWithCharLimit
                text={row.original.name || '(Untitled Course)'}
                limit={50}
                hideBtn
              />
              <Badge
                text={row.original.category?.name || 'Category'}
                size="sm"
                variant="outline"
              />
            </div>
          </div>
        );
      }
    },
    {
      Header: 'Creator',
      accessor: 'creator',
      Cell: ({ row }: { row: { original: ICourse } }) => (
        <>
          {formatNames(
            row.original?.creator?.first_name +
              ' ' +
              row.original?.creator?.last_name
          )}
        </>
      )
    },
    {
      Header: 'Price',
      accessor: 'price',
      Cell: ({ row }: { row: { original: ICourse } }) => (
        <div>
          <span>
            {row.original.price ? formatPrice(+row.original.price) : ' - '}
          </span>{' '}
          {!!row.original.price && (
            <span className={classes['u-semiBold']}>
              {row.original.currency?.toUpperCase()}
            </span>
          )}
        </div>
      )
    },
    {
      Header: 'Videos',
      accessor: 'videos_amount',
      Cell: ({ row }: { row: { original: ICourse } }) => (
        <>{row.original.video_count || '0'}</>
      )
    },
    {
      Header: 'Rating',
      accessor: 'rating',
      Cell: ({ row }: { row: { original: ICourse } }) => (
        <div>
          <>{row.original.avg_rating || '-'}</>
        </div>
      )
    }
  ];

  if (isSelectable) {
    cols.unshift({
      id: 'selection',
      Header: ({ toggleRowSelected, isAllPageRowsSelected, page, rows }) => {
        const modifiedOnChange = (event: any) => {
          page.forEach((row: any) => {
            toggleRowSelected(row.id, event.currentTarget.checked);
          });
        };

        // Count number of selectable and selected rows in the current page
        // to determine the state of select all checkbox
        let selectableRowsInCurrentPage = 0;
        let selectedRowsInCurrentPage = 0;
        rows.forEach((row: any) => {
          row.isSelected && selectedRowsInCurrentPage++;
          selectableRowsInCurrentPage++;
        });

        const checked =
          isAllPageRowsSelected ||
          selectableRowsInCurrentPage === selectedRowsInCurrentPage;

        return (
          <div>
            <IndeterminateCheckbox
              onChange={modifiedOnChange}
              checked={checked}
            />
          </div>
        );
      },
      maxWidth: tableColumnsWidths.selectionMax,
      minWidth: tableColumnsWidths.selectionMin,
      width: tableColumnsWidths.selection,
      Cell: ({ row }) => (
        <div>
          <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        </div>
      )
    });
  }

  if (isBundlePage) {
    cols[5] = {
      Header: 'Expires',
      accessor: 'expires',
      Cell: ({ row }: { row: { original: ICourse } }) => (
        <div>
          {moment(
            new Date(Date.parse(row.original.date_created)).setFullYear(
              new Date(Date.parse(row.original.date_created)).getFullYear() + 1
            )
          ).format(dateFormatUrl)}
        </div>
      )
    };
  }

  return cols;
};
