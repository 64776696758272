import { Portal } from 'react-portal';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';
import { ReactComponent as Check } from 'assets/icons/check-circle.svg';
import Button from 'components/buttons/Button';
import { ICourse } from 'query/course-module/dto';
import classes from './NavIsland.module.scss';

interface INavIslandProps {
  isEdit: boolean;
  isValid: boolean;
  isSubmitLoading: boolean;
  course?: ICourse;
  isBundle?: boolean;
  currentStep: number;
  setStep: (step: number) => void;
  onNextStepClick: () => void;
  submitForm: () => void;
}

const NavIsland = ({
  isEdit,
  isValid,
  isSubmitLoading,
  course,
  setStep,
  currentStep,
  onNextStepClick,
  submitForm,
  isBundle
}: INavIslandProps) => {
  return (
    <Portal node={document && document.getElementById('course-form__wrapper')}>
      <div className={classes['island']}>
        <div className={classes['island__container']}>
          <div className={classes['island__container__btns-container']}>
            <Button
              type="button"
              variant="neutral"
              onClick={() => setStep(currentStep - 1)}
              icon={Arrow}
              iconRotate={270}
              iconPosition="left"
              isDisabled={currentStep === 0}
            >
              Previous Step
            </Button>
            {currentStep < 4 && (
              <Button
                type="button"
                onClick={onNextStepClick}
                icon={Arrow}
                iconRotate={90}
              >
                Next Step
              </Button>
            )}
            {currentStep === 4 && (
              <Button
                type="submit"
                icon={Check}
                isDisabled={!isValid}
                isLoading={isSubmitLoading}
                onClick={submitForm}
              >
                {isBundle
                  ? 'Create Bundle'
                  : isEdit
                  ? course?.not_finished
                    ? 'Finish Draft'
                    : 'Update Course Info'
                  : 'Finish Course Creation'}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default NavIsland;
