import * as Yup from 'yup';
import { IOption } from 'components/input/InputChoice';
import {
  IEditSocialDto,
  IForgotPasswordDto,
  ILoginDto,
  IRegisterDto,
  IResetPasswordDto
} from 'query/acc-module/dto';
import {
  ICourse,
  ICourseBundle,
  ICourseDto,
  ISelectOption,
  IVideoDto
} from 'query/course-module/dto';
import { REACT_APP_MIN_COURSE_PRICE } from './constants';

const passMsg =
  'Password must be at least 8 characters and contain uppercase letter(s), lowercase letter(s), number(s) and symbol(s)';

export const PASSWORD_PATTERN =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@\[\]^_`{|}~])(?=.{8,})/; // eslint-disable-line

export const PHONE_PATTERN =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

// Ref. https://stackoverflow.com/questions/61634973/yup-validation-of-website-using-url-very-strict
export const WEBSITE_PATTERN =
  /^((ftp|http|https):\/\/)?(www\.)?(?!.*(ftp|http|https|www\.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/;

export const WALLET_PATTERN = /^0x[a-fA-F0-9]{40}$/g;

export const EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const NAME_PATTERN = /^[A-Za-z\s]+$/;

// Supports the following patterns   MM.DD.YYYY   DD.MM.YYYY   YYYY   Month Date, Year   Month Date Year   Date Month Year   DD/MM/YYYY   MM/DD/YYYY
export const DATE_PATTERN =
  /(\d{2}\.\d{2}\.\d{4})|(\d{2}\/\d{2}\/\d{4})|(\d{2}\.\d{2}\.\d{2})|(\d{2}\/\d{2}\/\d{2})|(\d{4})|([A-Za-z]+\s+\d{1,2},?\s*\d{4})|([A-Za-z]+\s+\d{1,2}\s*\d{4})|(\d{1,2}\s*[A-Za-z]+\s*\d{4})|(\d{2}-\d{2}-\d{4})|(\d{2}-\d{2}-\d{2})/;

// Patterns
export const yupSchemas = {
  str: Yup.string().trim(),
  number: Yup.number().min(0, 'Required: 0 or greater').required('Required'),
  requiredStr: Yup.string().trim().required('Required'),
  bool: Yup.boolean(),
  requiredBool: Yup.boolean().required('Required'),
  labelAndValue: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string()
    })
    .required('Required'),
  labelAndValueNonRequired: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string()
    })
    .nullable(),
  labelAndValueArr: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(),
        value: Yup.string().required()
      })
    )
    .required('Required'),
  attached_files: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(),
        data: Yup.string().required()
      })
    )
    .required(),
  email: Yup.string().email('Invalid email address').required('Required'),
  password: Yup.string()
    .matches(PASSWORD_PATTERN, passMsg)
    .required('Required'),
  confirm_password: Yup.string()
    .matches(PASSWORD_PATTERN, passMsg)
    .required('Required')
    .oneOf([Yup.ref('password')], "Passwords don't match"),
  new_pass: Yup.string()
    .matches(PASSWORD_PATTERN, passMsg)
    .required('Required'),
  confirm_new_pass: Yup.string()
    .matches(PASSWORD_PATTERN, passMsg)
    .required('Required')
    .oneOf([Yup.ref('new_pass')], "Passwords don't match"),
  about: Yup.string().max(150, 'Max 150 characters'),
  title: Yup.string().max(60, 'Max 60 characters').required('Required'),
  short_description: Yup.string().max(200, 'Max 200 characters'),
  description: Yup.string().max(500, 'Max 500 characters').required('Required'),
  videoDescription: Yup.string().max(300, 'Max 300 characters'),
  phone: Yup.string()
    .max(20, 'Please enter a correct phone number')
    .matches(PHONE_PATTERN, {
      message: 'Please enter a complete phone number',
      excludeEmptyString: true
    })
    .required('Required'),
  phoneNonRequired: Yup.string()
    .max(20, 'Please enter a correct phone number')
    .matches(PHONE_PATTERN, {
      message: 'Please enter a complete phone number',
      excludeEmptyString: true
    }),
  fax: Yup.string()
    .max(20, 'Please enter a correct fax number')
    .matches(PHONE_PATTERN, {
      message: 'Please enter a complete fax number',
      excludeEmptyString: true
    }),
  website: Yup.string().matches(WEBSITE_PATTERN, {
    message: 'Please enter a correct url',
    excludeEmptyString: true
  }),
  wallet: Yup.string().matches(WALLET_PATTERN, {
    message: 'Please enter a correct wallet address',
    excludeEmptyString: true
  }),
  giftReceivers: Yup.array()
    .of(
      Yup.string()
        .matches(WALLET_PATTERN, {
          message: 'Please enter a correct wallet address',
          excludeEmptyString: true
        })
        .required('Each element must be a non-empty string')
    )
    .required('The array cannot be empty'),
  courseIds: Yup.array().of(Yup.string()).min(1, 'Required'),
  thumbnail: Yup.string().required(),
  coursePrice: Yup.number()
    .min(
      REACT_APP_MIN_COURSE_PRICE,
      `The price must be at least ${REACT_APP_MIN_COURSE_PRICE} USDC`
    )
    .required('Required'),
  discount: Yup.number().min(0).max(100).required('Required')
};

export interface ILoginFormFields extends Omit<ILoginDto, 'remember'> {
  remember: IOption[];
}

const CommonAuthValidation = {
  email: yupSchemas.email,
  password: yupSchemas.password
};

const ResetPasswordValidation = {
  new_pass: yupSchemas.new_pass,
  confirm_new_pass: yupSchemas.confirm_new_pass
};

export const LoginSchema: Yup.ObjectSchema<ILoginFormFields> =
  Yup.object().shape({
    ...CommonAuthValidation,
    remember: yupSchemas.labelAndValueArr
  });

export interface IRegFormFields extends IRegisterDto {}

export const RegisterSchema: Yup.ObjectSchema<IRegFormFields> =
  Yup.object().shape({
    ...CommonAuthValidation,
    confirm_password: yupSchemas.confirm_password,
    first_name: yupSchemas.requiredStr.matches(
      /^(?!(http(s)?:\/\/(www\.)?)|(^www\.))/,
      'URL is not allowed'
    ),
    last_name: yupSchemas.requiredStr.matches(
      /^(?!(http(s)?:\/\/(www\.)?)|(^www\.))/,
      'URL is not allowed'
    ),
    pic: yupSchemas.str
  });

export interface IEditAuthFormFields
  extends Pick<ILoginFormFields, 'email' | 'password'>,
    IResetPasswordFormFields {}

export const EditAuthSchema: Yup.ObjectSchema<IEditAuthFormFields> =
  Yup.object().shape({
    ...CommonAuthValidation,
    ...ResetPasswordValidation
  });

export interface IEditProfileFormFields
  extends Pick<IRegisterDto, 'first_name' | 'last_name'> {
  about?: string;
  pic?: string;
  banner?: string;
}

export const EditProfileSchema: Yup.ObjectSchema<IEditProfileFormFields> =
  Yup.object().shape({
    first_name: yupSchemas.requiredStr.matches(
      /^(?!(http(s)?:\/\/(www\.)?)|(^www\.))/,
      'URL is not allowed'
    ),
    last_name: yupSchemas.requiredStr.matches(
      /^(?!(http(s)?:\/\/(www\.)?)|(^www\.))/,
      'URL is not allowed'
    ),
    about: yupSchemas.about.matches(
      /^(?!(http(s)?:\/\/(www\.)?)|(^www\.))/,
      'URL is not allowed'
    ),
    pic: yupSchemas.str,
    banner: yupSchemas.str
  });

export interface IEditSocialFormFields extends IEditSocialDto {}

export const EditSocialSchema: Yup.ObjectSchema<IEditSocialFormFields> =
  Yup.object().shape({
    website: yupSchemas.website,
    twitter: yupSchemas.str,
    instagram: yupSchemas.str,
    facebook: yupSchemas.str
  });

export interface IResetPasswordFormFields extends IResetPasswordDto {
  confirm_new_pass: string;
}

export const ResetPasswordSchema: Yup.ObjectSchema<IResetPasswordFormFields> =
  Yup.object().shape({
    ...ResetPasswordValidation
  });

export interface IForgotPasswordFields extends IForgotPasswordDto {}

export const ForgotPasswordSchema: Yup.ObjectSchema<IForgotPasswordFields> =
  Yup.object().shape({
    email: yupSchemas.email
  });

export interface ICourseFormFields
  extends Omit<
    ICourseDto,
    'expiration' | 'cpe' | 'template_id' | 'thumbnail' | 'video_preview'
  > {
  expiration: IOption[];
  cpe: IOption[];
  draft_id?: string;
  template_id: ISelectOption[];
  thumbnail: string;
  video_preview: string;
  isThumbnailPlaceholder?: boolean;
  thumbnail_is_generated?: boolean;
  video_preview_is_generated?: boolean;
}

export interface ICourseBundleFormFields extends ICourseBundle {
  courses: ICourse[];
  courseIds: string[];
}

// @ts-ignore
export const CourseBundleSchema: Yup.ObjectSchema<ICourseBundleFormFields> =
  Yup.object().shape({
    name: yupSchemas.title,
    price: yupSchemas.coursePrice,
    discount: yupSchemas.discount,
    thumbnail: yupSchemas.thumbnail,
    courseIds: yupSchemas.courseIds,
    description: yupSchemas.description,
    not_finished: yupSchemas.bool,
    category: yupSchemas.labelAndValue,
    expiration: yupSchemas.labelAndValueArr,
    currency: yupSchemas.labelAndValue
  });

// @ts-ignore
export const CourseSchema: Yup.ObjectSchema<ICourseFormFields> =
  Yup.object().shape({
    name: yupSchemas.title,
    category: yupSchemas.labelAndValue,
    subcategory: yupSchemas.labelAndValueNonRequired,
    short_description: yupSchemas.short_description,
    description: yupSchemas.description,
    thumbnail: yupSchemas.str,
    activation_nft_image: yupSchemas.str,
    video_preview: yupSchemas.str,
    hover_play: yupSchemas.bool,
    show_video_preview: yupSchemas.bool,
    expiration: yupSchemas.labelAndValueArr,
    currency: yupSchemas.labelAndValue,
    price: yupSchemas.coursePrice,
    cpe: yupSchemas.labelAndValueArr,
    template_id: yupSchemas.labelAndValueArr
  });

export interface IVideoFormFields extends IVideoDto {
  has_test?: IOption[];
}

// @ts-ignore
export const VideoSchema: Yup.ObjectSchema<IVideoFormFields> =
  Yup.object().shape({
    title: yupSchemas.title,
    description: yupSchemas.videoDescription,
    thumbnail: yupSchemas.str,
    attached_files: yupSchemas.attached_files,
    completion_score: yupSchemas.labelAndValueNonRequired
  });

export interface IGiftRecepientsFields {
  giftReceivers: string[];
}

export const IGiftRecepientsSchema: Yup.ObjectSchema<IGiftRecepientsFields> =
  Yup.object().shape({
    giftReceivers: yupSchemas.giftReceivers
  });
