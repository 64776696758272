import { BigNumberish } from 'ethers';
import { IUser } from 'context/userContext';
import {
  COURSE_STATUS,
  COURSE_REVIEW_STATUS,
  VIDEO_STATUS,
  COURSE_EXPIRY,
  TRANSACTION_STATUS
} from 'utils/constants';
import { ICourseBundleFormFields, ICourseFormFields } from 'utils/yupSchemas';
import { ICreatorsData } from 'query/acc-module/dto';
import { IOption } from 'components/input/InputWrapper';

export enum COMPLETION_STATUS {
  INITIAL = 0,
  COMPLETED = 1,
  PENDING_MINT = 2,
  MINTED = 3
}

export enum COURSE_RELATIONSHIP {
  NONE = 0,
  PURCHASED = 1,
  CREATOR = 2
}

export enum COURSE_CHANGES {
  // Do not include any changes
  NO_CHANGES = '0',
  // return only the changes
  ONLY_CHANGES = '1',
  // return both the changes and originals
  ALL = '2'
}

export enum COURSE_SEGMENT {
  MOST_POPULAR = 1,
  BEST_SELLERS = 2,
  RECOMMENDED = 3
}

export enum VIDEO_EDIT_STATUS {
  NEW = 0,
  DELETED = 1,
  EDITED = 2,
  INITIAL = 3
}

export enum FILE_CHANGE {
  ADD = 1,
  DELETE = 2
}

export enum COURSE_HISTORY_STATUS {
  APPROVE = 0,
  REJECT = 1,
  TAKE_DOWN = 2,
  RESTORE = 3
}

export interface ISelectOption {
  value: string | number;
  label: string;
  symbol?: string;
  img?: string;
}

export interface ICourseDto {
  name: string;
  category: ISelectOption | null;
  subcategory: ISelectOption | null;
  short_description?: string;
  description?: string;
  thumbnail?: {
    data: string;
    is_default: boolean;
    thumbnail_is_generated?: boolean;
  };
  activation_nft_image?: string;
  video_preview?: {
    data: string;
    is_default: boolean;
    video_preview_is_generated?: boolean;
  };
  hover_play?: boolean;
  show_video_preview?: boolean;
  expiration: COURSE_EXPIRY;
  currency: ISelectOption | null;
  price: string;
  cpe: boolean;
  template_id: string;
  not_finished: boolean;
  has_draft_copy?: boolean;
}

export interface IEditCourseDto {
  data: ICourseDto;
  id: string;
}

export interface IEditCourseFormFields {
  data: ICourseFormFields;
  id: string;
}

export interface ICourse {
  _id: string;
  name: string;
  description: string;
  short_description: string;
  category: ICourseCategory;
  subcategory: ICourseCategory;
  expiration: COURSE_EXPIRY;
  price: string;
  currency: string;
  template_id: string;
  status: COURSE_STATUS;
  review_status: COURSE_REVIEW_STATUS;
  date_created: string;
  creator_id: string;
  activation_nft_image: string;
  thumbnail: string;
  video_preview: string;
  thumbnail_is_generated?: boolean;
  video_preview_is_generated?: boolean;
  video_count: number;
  hover_play?: string;
  show_video_preview?: string;
  not_finished?: boolean;
  creator: IUser;
  cpe: boolean;
  course_relationship: COURSE_RELATIONSHIP;
  progress: number;
  purchased: IPurchasedCourse;
  has_draft_copy: boolean;
  is_draft_copy: boolean;
  token_id?: number;
  list_tx_started_at: string;
  list_tx_hash: string;
  slug: string;
  receipt_id: number;
  reject_reason: string;
  exams_count: number;
  // diffs has the structure of ICourse, with all fields being
  // a boolean, thus indicating if the field has been changed
  diffs: ICourseDiffs;
  original: ICourse;
  is_highlighted?: boolean;
  updated_at: string;
  update_tx_started_at?: string;
  avg_rating: number;
  ratings: {
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
  };
  hasRated: boolean;
}

export interface ICourseBundle {
  _id: string;
  name: string;
  price: number;
  discount: string;
  thumbnail: string;
  courses: ICourse[];
  description: string;
  expiration: IOption[];
  not_finished: boolean;
  category: ISelectOption;
  currency: ISelectOption | null;
}

export interface ICourseBundleDto extends IData {
  result: ICourseBundle[];
}

export interface IEditCourseBundleFormFields {
  data: ICourseBundleFormFields;
  id: string;
}

interface IKeyValuePairsBooleans {
  [key: string]: boolean; // Many key-value pairs of type boolean
}

interface IVideoDiffs {
  videos_diff: {
    [key: string]: {
      title: IVideo['title'];
      description: IVideo['description'];
      thumbnail: IVideo['thumbnail'];
      exam: IVideo['exam'];
      completion_score: IVideo['completion_score'];
      index: IVideo['index'];
      path: IVideo['path'];
      slug: IVideo['slug'];
      change: number;
      file: {
        [key: string]: boolean;
      };
      file_changes?: FILE_CHANGE;
    };
  };
}

type ICourseDiffs = IKeyValuePairsBooleans & IVideoDiffs;

export interface IPurchasedCourse {
  _id: string;
  active: boolean;
  completion_status: COMPLETION_STATUS;
  course: ICourse;
  course_id: string;
  user_id: string;
  videos_checkpoint: {
    [key: string]: string;
  };
  videos_completed: {
    [key: string]: boolean;
  };
  videos_watched: {
    [key: string]: boolean;
  };
  creator: IUser;
  buy_tx_confirmed_at: string;
  buy_tx_hash: string;
  buy_tx_started_at: string;
  refund_tx_started_at?: number;
  activate_tx_confirmed_at: string;
  activate_tx_hash: string;
  activate_tx_started_at: string;
  claimed: boolean;
  feeCollected: boolean;
  last_watched_video: string;
  receipt_id: number;
  refunded: boolean;
  tests_completed: { [key: string]: boolean };
  opus_nft_completion: string;
  diploma_link: string;
  diploma_mint_tx_hash: string;
  ipfs_diploma_id: string;
  updated_at: string;
  seen: boolean;
  first_video: string;
  purchased_by_user_id: string;
  certificate_token_id: string;
}

export interface IPurchasedItem extends IPurchasedCourse {
  course: ICourse;
  action: TRANSACTION_STATUS;
  course_name: string;
  course_price: number;
  buyer_name: string;
  buyer_slug: String;
  platform_fee: number;
  creator_revenue: number;
  course_creator_slug: string;
  course_creator_name: string;
  purchased_by_wallet: string;
  purchased_by_user_id: string;
  course_creator_wallet: string;
  can_manually_refund?: boolean;
  is_manually_refunded?: boolean;
  creator_fee_claim_tx_started_at?: number;
  creator_fee_claim_tx_confirmed_at?: number;
  admin_fee_collect_tx_started_at?: number;
  admin_fee_collect_tx_confirmed_at?: number;
}

export interface IData {
  minCourse: number;
  maxCourse: number;
  total_pages: number;
  total_results: number;
  all_records: number;
}

export interface ICourseData extends IData {
  result: ICourse[];
}

export interface IPurchasedCourseData extends IData {
  result: IPurchasedCourse[];
}

export interface IPurchasedItemsData extends IData {
  result: IPurchasedItem[];
}

export interface IUsersData extends IData {
  result: IUser[];
}

export interface ICourseAndCreatorsData {
  courses: ICourseData;
  creators: ICreatorsData;
}

export interface ICourseCategoryData extends Omit<IData, 'total_pages'> {
  result: ICourseCategory[];
}

export interface ICourseCategory {
  _id: string;
  created_by: string;
  date_created: string;
  updated_at: string;
  name: string;
  slug: string;
  counter: number;
  deleted: boolean;
  icon: string;
  subCategories?: ICourseCategory[];
}

export interface IExam {
  label: string;
  answers: { value: string; id: number }[];
  correct_answer: number;
}

export interface IAttachedFile {
  name: string;
  data: string;
}

export interface IVideoAllFiles {
  files: IVideoFile[];
  video_id: string;
  video_title: string;
}

export interface IVideoFile {
  _id: string;
  name: string;
  video_id: string;
  course_id: string;
  creator_id: string;
  date_created: string;
  file_change: FILE_CHANGE;
}

export interface IModifyFile {
  name: string;
  modify?: {
    id: string;
    action: 'delete' | 'reset';
  };
}

export interface IVideo {
  _id: string;
  course_id: string;
  status: VIDEO_STATUS;
  creator_id: string;
  date_created: string;
  index: number;
  path: string;
  title: string;
  description?: string;
  thumbnail?: string;
  exam?: IExam[];
  completion_score?: number;
  file_count?: number;
  edit_status: VIDEO_EDIT_STATUS;
  file_changes: { [key: string]: number };
  next_video_id?: string;
  slug?: string;
  original: IVideo;
}

export interface IResourceVideo extends IVideo {}

export interface ISingleCourseVideo extends IVideo {
  files?: IVideoFile[];
  draft: boolean;
}

export interface IAnswerSubmitted {
  answer_submitted: number;
  is_correct: boolean;
}

export interface ITest {
  exam: IExam[];
  video_id: string;
  course_id: string;
  completion_score: number;
  video_title: string;
  creator_id: string;
  test_passed: boolean;
  last_submission: {
    score: number;
    answers: IAnswerSubmitted[];
  };
}

export interface ICourseResourcesData {
  files: IVideoAllFiles[];
  tests: ITest[];
  videos: IResourceVideo[];
  videos_checkpoint: {
    [key: string]: string;
  };
  videos_watched: {
    [key: string]: string;
  };
}

export interface IVideoDto {
  title?: string;
  description?: string;
  thumbnail?: string;
  completion_score?: ISelectOption | null;
  attached_files?: (IAttachedFile | IModifyFile)[];
  hidden_files?: IVideoFile[];
  hidden_field?: IModifyFile[];
  [key: `exam_question_${number}`]: string;
  [key: `exam_answer_${number}_${number}`]: string;
  [key: `exam_correctAnswer_${number}`]: string;
}

export interface IEditVideoDto {
  data: IVideoDto;
  courseId: string;
  videoId: string;
}

export interface IEditVideoOrderDto {
  data: IResourceVideo[];
  courseId: string;
}

export interface ICreateTemplateDto
  extends Pick<
    ITemplateDto,
    'background' | 'background_name' | 'fields' | 'name'
  > {}

export interface ITemplateDto {
  _id: string;
  name: string;
  background?: string;
  background_name: string;
  date_created: number;
  thumbnail: string;
  fields: [
    {
      name: string;
      fontSize: number;
      fontWeight: number;
      lineHeight: number;
      top: number;
      left: number;
      width: number;
      height: number;
      visible: true;
      fontStyle: string;
      fontColor: string;
      label: string;
      textAlign: string;
      preview: {
        label: string;
        value: string;
      };
      whiteSpace: string;
    }
  ];
}

export interface ITemplatesListDto extends IData {
  result: ITemplateDto[];
}

export interface ISignatureDto {
  itemData: IListInputItemData;
  paymentData: IPaymentData;
  sigExpiry: number;
  signature: string;
}

export interface IRepublishSignatureDto {
  item_id: number;
  updateItemData: IUpdateItemData;
  paymentData: IPaymentData;
  sigExpiry: number;
  signature: string;
}

export interface IListInputItemData {
  lister?: string;
  listingFee: BigNumberish;
  purchaseEndPeriod: number;
  purchasePrice: BigNumberish;
  refundPeriod: number;
  service: string;
  serviceData: string;
}

export interface IPublishCourseDto {
  payment_data: IListData;
}

export interface IBuyCourseDto {
  buy_data: {
    recepients: string[];
  };
  payment_data: IPaymentData;
}

export interface IBuyCourseResponseDto {
  buyData: {
    itemId: BigNumberish; // this is course.token_id
    buyer: string;
    recepients: string[];
    refundPeriodDate: number;
  };
  paymentData: IPaymentData;
  sigExpiry: number;
  signature: string;
}

export type IPaymentData = {
  token: string; // tokenIn
  fee: BigNumberish; // fee
  amount: BigNumberish; // amountInMaximum
  allowedSlippage: BigNumberish;
  sqrtPriceLimitX96: BigNumberish;
};

export interface IUpdateItemData {
  purchaseEndPeriod: number;
  purchasePrice: BigNumberish;
  refundPeriod: number;
  updatingFee: number;
}

export interface IListData extends IPaymentData {
  listingFee: BigNumberish;
}

interface ITokenPair {
  default: boolean;
  poolFee: number;
  poolId: string;
  tokenDecimals: number;
  tokenAddress: string;
  tokenLogo: string;
  tokenName: string;
  tokenSymbol: string;
  _id: string;
}

interface IAdminTokenPair extends ITokenPair {
  last_recorded_liquidity_usd: string;
  active: boolean;
}

export interface ITokenPairsData {
  default: ITokenPair;
  tokens: ITokenPair[];
}

export interface IAdminTokenPairsData
  extends Pick<IData, 'total_results' | 'all_records'> {
  result: IAdminTokenPair[];
}

export interface IFilterOptions {
  label: string;
  value: string | number;
}

export interface IFilterData {
  filterName: string;
  filterOptions: IFilterOptions[];
}

export interface ISinglePageData {
  slug: string;
  title: string;
  content: string;
}

export interface ISinglePageDto {
  result: ISinglePageData[];
}

export interface IReceivedCertificatesListDto extends IData {
  result: ICourse['purchased'][];
}

export interface IAdminStatsData {
  activated_nfts: number;
  fee_collected: number;
  published_courses: number;
  total_users: number;
  user_change: { percentDifference: number };
  courses_change: { percentDifference: number };
  nft_activated_change: { percentDifference: number };
}

export interface IUsersData extends Pick<IData, 'total_results'> {
  result: IUser[];
}

export interface IPlatformSettingsDto {
  free_video_count: number;
  platform_fee: number;
  video_fee: number;
}

export interface IPublishFeesDto {
  creator_uploaded_videos: number;
  course_video_count: number;
  additional_fee: number;
  settings: {
    free_video_count: number;
    video_fee: number;
  };
}

export interface ICourseReviewDto extends IData {
  result: ICourseReview[];
}

export interface ICourseReview {
  _id: string;
  course_id: string;
  rating: number;
  content: string;
  user_id: string;
  date_created: string;
  user: IUser;
  hidden: boolean;
}

export interface ICourseHistory {
  admin: IUser;
  reason?: string;
  date_created: string;
  action: COURSE_HISTORY_STATUS;
}

export interface ICourseHistoryData extends IData {
  result: ICourseHistory[];
}

export interface IReport {
  _id: string;
  total_sold: number;
  platform_fee: number;
  total_revenue: number;
  creator_revenue: number;
  available_states: string[] | [];
}

export interface IReportData {
  result: IReport[];
}

export interface IAdminTaxReport extends IReportData {
  available_countries: string[];
}

export interface IUserNotificationsDto {
  _id: string;
  date_created: string;
  link: string;
  message: string;
  seen: boolean;
  updated_at: string;
  user_id: string;
}
