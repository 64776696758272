import { useContext, useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useAccount, useSwitchNetwork } from 'wagmi';
import queryString from 'query-string';
import ThumbnailPlaceholder from 'assets/images/thumbnail-placeholder.png';
import { ReactComponent as Image } from 'assets/icons/image.svg';
import { ReactComponent as Switch } from 'assets/icons/switch.svg';
import { Web3Context } from 'context/web3Context';
import { userContext } from 'context/userContext';
import { popupContext } from 'context/popupContext';
import { RenderBadge } from 'components/badge/RenderBadge';
import TextWithCharLimit from 'components/textWithCharLimit/TextWithCharLimit';
import Player from 'components/player/Player';
import Badge from 'components/badge/Badge';
import PublishPopup from '../components/popups/PublishPopup';
import Button from 'components/buttons/Button';
import InfoBox from 'components/info-box/InfoBox';
import { PublishedChangesMsg } from '../components/PublishedChangesMsg';
import ChildWithIndicator from '../components/ChildWithIndicator';
import CourseChangesMenu from '../components/Changes/CourseChangesMenu';
// import RefundPopup from '../components/popups/RefundPopup';
import SEO from 'components/SEO/SEO';
import RatingComponent from 'components/rating/Rating';
import PublishCourseCTAPopup from '../components/popups/PublishCourseCTAPopup';
import AdminButtons from './button-views/AdminButtons';
import CreatorButtons from './button-views/CreatorButtons';
import UserButtons from './button-views/UserButtons';
// import TransferActivationPopup from '../components/TransferActivationPopup';
import {
  COURSE_CHANGES,
  COURSE_RELATIONSHIP,
  ICourse
} from 'query/course-module/dto';
import {
  COURSE_EXPIRY,
  COURSE_REVIEW_STATUS,
  COURSE_STATUS,
  REACT_APP_CHAIN_ID,
  REACT_APP_FILES_EDU_PUBLIC_DOMAIN
} from 'utils/constants';
import { getPriceInUsdc, isCourseStatus } from 'utils/helpers';
import { dateFormatUrl } from 'utils/static';
import { formatNames } from 'utils/format';
import { currencyObj } from '..';
import classes from '../SingleCourse.module.scss';

interface ICourseViewProps {
  data: ICourse;
  isAdminPage: boolean;
}

const CourseView = ({ data, isAdminPage }: ICourseViewProps) => {
  const { slug } = useParams();
  const { search: urlParams } = useLocation();
  const { changes, is_public_view } = queryString.parse(urlParams);
  const isCreatorPublicView = is_public_view === 'true';
  const isDraftOfPublished = changes === COURSE_CHANGES.ONLY_CHANGES;
  const navigate = useNavigate();
  const { userData, isTokenExpired } = useContext(userContext);
  const { setPopup, setMenu } = useContext(popupContext);
  const { isCorrectChain } = useContext(Web3Context);

  // Web3 hooks
  const { isConnected } = useAccount();
  const { switchNetwork } = useSwitchNetwork();

  const isSubmitted = isCourseStatus('isSubmitted', data);
  const isApproved = isCourseStatus('isApprovedInitial', data);
  const isPublished = isCourseStatus('isPublished', data);
  const isPublishing = isCourseStatus('isPublishing', data);
  const isRejected = isCourseStatus('isRejected', data);
  const isTakedown = isCourseStatus('isTakedown', data);

  const changedFields = useCallback(() => {
    const fields = {
      name: {
        title: 'Course Name',
        oldValue: data?.original?.name as string,
        newValue: data.name as string,
        valueType: 'text'
      },
      category: {
        title: 'Category',
        oldValue: data?.original?.category.name as string,
        newValue: data.category.name as string,
        valueType: 'text'
      },
      subcategory: {
        title: 'Subcategory',
        oldValue: data?.original?.subcategory?.name as string,
        newValue: data?.subcategory?.name as string,
        valueType: 'text'
      },
      short_description: {
        title: 'Short description',
        oldValue: data?.original?.short_description as string,
        newValue: data.short_description as string,
        valueType: 'text'
      },
      description: {
        title: 'Description',
        oldValue: data?.original?.description as string,
        newValue: data.description as string,
        valueType: 'text'
      },
      thumbnail: {
        title: 'Course Thumbnail',
        oldValue: data?.original?.thumbnail as string,
        newValue: data.thumbnail as string,
        valueType: 'image'
      },
      activation_nft_image: {
        title: 'Activation NFT Image',
        oldValue: data?.original?.activation_nft_image,
        newValue: data?.activation_nft_image,
        valueType: 'image'
      },
      video_preview: {
        title: 'Video Preview',
        oldValue: data?.original?.video_preview as string,
        newValue: data.video_preview as string,
        valueType: 'video'
      },
      price: {
        title: 'Price Update',
        oldValue: data?.original?.price as string,
        newValue: data?.price as string,
        valueType: 'price'
      },
      expiration: {
        title: 'Time Limit',
        oldValue: COURSE_EXPIRY[data?.original?.expiration] as string,
        newValue: COURSE_EXPIRY[data?.expiration] as string,
        valueType: 'text'
      },
      template_id: {
        title: 'Certificate',
        oldValue: data?.original?.template_id,
        newValue: data?.template_id,
        valueType: 'certificate'
      }
    } as any;
    const changedFields: any = [];
    Object.keys(fields).map((key) => {
      if (!!data?.diffs[key]) {
        return changedFields.push({ ...fields[key], fieldKey: key });
      }
      return null;
    });

    return changedFields.filter((field: any) => field !== undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.diffs]);

  const {
    name,
    category,
    subcategory,
    description,
    thumbnail,
    expiration,
    price,
    currency,
    review_status,
    creator_id,
    course_relationship,
    video_preview,
    show_video_preview,
    creator,
    reject_reason,
    ratings,
    avg_rating,
    date_created
  } = data;

  const isPublic =
    userData && userData._id && userData._id !== ''
      ? creator_id !== userData._id
      : true;

  // Show PublishCourseCTAPopup only once per course
  useEffect(() => {
    if (course_relationship !== COURSE_RELATIONSHIP.CREATOR) return;

    const publishCtaCourses = JSON.parse(
      localStorage.getItem('publish_cta') as string
    );

    if (
      ((isCourseStatus('isPublished', data) &&
        review_status === COURSE_REVIEW_STATUS.APPROVED &&
        changes === COURSE_CHANGES.ONLY_CHANGES &&
        !data.update_tx_started_at) ||
        (isCourseStatus('isApprovedInitial', data) &&
          !data.list_tx_started_at)) &&
      !publishCtaCourses?.[data._id]
    ) {
      localStorage.setItem(
        'publish_cta',
        JSON.stringify({ ...publishCtaCourses, [data._id]: true })
      );
      setPopup(
        <PublishCourseCTAPopup
          onClick={() => setPopup(<PublishPopup data={data} />)}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const courseVersionsLink = (
    <>
      {(isPublished || isTakedown) &&
        (data.is_draft_copy || data.has_draft_copy) &&
        (isAdminPage ||
          course_relationship === COURSE_RELATIONSHIP.CREATOR) && (
          <div>
            <Button
              className={`${classes['u-underline']} ${classes['u-body3']}`}
              variant="link-contrast"
              withPadding={false}
              onClick={() => {
                if (isAdminPage)
                  return navigate(
                    `/admin/courses/${slug}${
                      changes === COURSE_CHANGES.NO_CHANGES
                        ? '?changes=2'
                        : '?changes=0'
                    }`
                  );
                else
                  navigate(
                    `/courses/${slug}${
                      changes === COURSE_CHANGES.NO_CHANGES
                        ? '?changes=1'
                        : '?changes=0'
                    }`
                  );
              }}
            >
              View{' '}
              {changes === COURSE_CHANGES.NO_CHANGES ? 'Updated' : 'Published'}{' '}
              Version
            </Button>
          </div>
        )}
    </>
  );

  // Admin sees a message about the course status and necessary actions to take (if any)
  const statusMessage = (
    <>
      <span className={classes['u-body3']}>
        This course is{' '}
        {review_status === COURSE_REVIEW_STATUS.IN_REVIEW
          ? changes === COURSE_CHANGES.NO_CHANGES
            ? `${
                data.status === COURSE_STATUS.PAUSED ||
                data.status === COURSE_STATUS.PUBLISHED
                  ? data.status === COURSE_STATUS.PAUSED
                    ? 'taken down. It also has a new version.'
                    : 'published. It also has a new version.'
                  : 'submitted for approval for the first time. You should review it and either approve or reject it.'
              }`
            : 'submitted for approval. You should review it and either approve or reject it.'
          : review_status === COURSE_REVIEW_STATUS.APPROVED
          ? changes === COURSE_CHANGES.NO_CHANGES && data.has_draft_copy
            ? `${
                data.status === COURSE_STATUS.PAUSED
                  ? 'taken down'
                  : 'published'
              }. It also has a new version.`
            : 'was approved and the content creator can publish it.'
          : review_status === COURSE_REVIEW_STATUS.REJECTED
          ? changes === COURSE_CHANGES.NO_CHANGES
            ? `${
                data.status === COURSE_STATUS.PAUSED ||
                data.status === COURSE_STATUS.PUBLISHED
                  ? data.status === COURSE_STATUS.PAUSED
                    ? 'taken down. It also has a new version.'
                    : 'published. It also has a new version.'
                  : 'was rejected and the content creator should apply the requested changes.'
              }`
            : 'was rejected and the content creator should apply the requested changes.'
          : review_status === COURSE_REVIEW_STATUS.INITIAL
          ? changes === COURSE_CHANGES.NO_CHANGES
            ? `${
                data.status === COURSE_STATUS.PAUSED
                  ? 'taken down and not publicly visible'
                  : 'published'
              }. ${
                data.has_draft_copy
                  ? 'It also has a draft version, but no action is required prior to its submission.'
                  : ''
              }`
            : 'a draft, not yet submitted for approval.'
          : ''}
        {courseVersionsLink}
      </span>
    </>
  );

  // Creators see a link to navigate between course versions
  const linkToVersion = !isAdminPage &&
    course_relationship === COURSE_RELATIONSHIP.CREATOR &&
    (isPublished || isTakedown) &&
    (data.is_draft_copy || data.has_draft_copy) && (
      <div>
        <Button
          className={`${classes['u-underline']} ${classes['u-body3']}`}
          variant="link-contrast"
          withPadding={false}
          onClick={() => {
            if (isAdminPage)
              return navigate(
                `/admin/courses/${slug}${
                  changes === COURSE_CHANGES.NO_CHANGES
                    ? '?changes=2'
                    : '?changes=0'
                }`
              );
            else
              navigate(
                `/courses/${slug}${
                  changes === COURSE_CHANGES.NO_CHANGES
                    ? '?changes=1'
                    : '?changes=0'
                }`
              );
          }}
        >
          View {changes === COURSE_CHANGES.NO_CHANGES ? 'Updated' : 'Published'}{' '}
          Version
        </Button>
      </div>
    );

  return (
    <div className={classes['main']}>
      <SEO
        type="product"
        title={data.name}
        desc={data.description}
        image={data.thumbnail}
        creatorName={formatNames(
          data.creator.first_name + ' ' + data.creator.last_name
        )}
      />
      <div className={classes['main__img-wrapper']}>
        {!!video_preview && show_video_preview ? (
          <Player
            src={REACT_APP_FILES_EDU_PUBLIC_DOMAIN + video_preview}
            hasControls
          />
        ) : thumbnail ? (
          <img
            src={REACT_APP_FILES_EDU_PUBLIC_DOMAIN + thumbnail}
            alt={!!name ? name : 'Course Name'}
          />
        ) : (
          <img src={ThumbnailPlaceholder} alt={'OpusEDU Create Course'} />
        )}
      </div>
      <div className={classes['main__content']}>
        {(!isPublic || isAdminPage) && !isCreatorPublicView && (
          <div className={classes['main__content__badge-container']}>
            <RenderBadge course={data} />
            {isAdminPage && statusMessage}
          </div>
        )}
        <ChildWithIndicator
          className={classes['main__content__title-with-indicator']}
          showIndicator={data?.diffs?.name && isAdminPage}
          onClick={() =>
            setMenu(
              <CourseChangesMenu changes={changedFields()} focusChange="name" />
            )
          }
          children={
            <div>
              <h1 className={`${classes['u-semiBold']} ${classes['u-h3']}`}>
                {name}
              </h1>
              {linkToVersion}
            </div>
          }
        />
        <div className={classes['main__content__badges']}>
          {!!category?.name && (
            <ChildWithIndicator
              showIndicator={data?.diffs?.category && isAdminPage}
              onClick={() =>
                setMenu(
                  <CourseChangesMenu
                    changes={changedFields()}
                    focusChange={'category'}
                  />
                )
              }
              children={
                <Badge
                  text={category.name}
                  badgeType="primary"
                  size="sm"
                  onClick={() => navigate(`/category/${category.slug}`)}
                />
              }
            />
          )}
          {/* {!!subcategory?.name && (
            <ChildWithIndicator
              showIndicator={data?.diffs?.subcategory && isAdminPage}
              onClick={() =>
                setMenu(
                  <CourseChangesMenu
                    changes={changedFields()}
                    focusChange={'subcategory'}
                  />
                )
              }
              children={
                <Badge
                  text={subcategory.name}
                  variant="outline"
                  size="sm"
                  onClick={() =>
                    navigate(`/category/${category.slug}/${subcategory.slug}`)
                  }
                />
              }
            />
          )} */}
        </div>
        {(course_relationship !== COURSE_RELATIONSHIP.CREATOR ||
          is_public_view === 'true') && (
          <div>
            <span className={classes['details__title']}>Created by </span>
            <Button
              variant="link-contrast"
              withPadding={false}
              onClick={() => navigate(`/user/${creator.slug}/about/main-info`)}
              className={classes['u-underline']}
            >
              {formatNames(creator.first_name + ' ' + creator.last_name)}
            </Button>
          </div>
        )}
        <div className={classes['details']}>
          <ChildWithIndicator
            showIndicator={data?.diffs?.description && isAdminPage}
            onClick={() =>
              setMenu(
                <CourseChangesMenu
                  changes={changedFields()}
                  focusChange="description"
                />
              )
            }
            children={
              <div className={classes['details__title']}>
                Course Description
              </div>
            }
          />
          <div className={classes['details__content']}>
            <TextWithCharLimit text={description} limit={300} />
          </div>
        </div>
        <div className={classes['details']}>
          <RatingComponent
            rating={avg_rating}
            count={ratings}
            readOnly
            onClick={() =>
              navigate(`/courses/${slug}/reviews?&changes=${changes}`, {
                state: { autoFocus: true }
              })
            }
          />
        </div>
        <div className={classes['details']}>
          <ChildWithIndicator
            showIndicator={data?.diffs?.price && isAdminPage}
            onClick={() =>
              setMenu(
                <CourseChangesMenu
                  changes={changedFields()}
                  focusChange="price"
                />
              )
            }
            children={
              <div className={classes['details__title']}>Course Price</div>
            }
          />
          <div className={classes['details__content']}>
            <div className={classes['details__content__price']}>
              {price ? (
                <>
                  <img
                    src={currencyObj[currency]?.symbol}
                    alt="Symbol"
                    width={32}
                    height={32}
                  />
                  <span>
                    <span
                      className={classes['details__content__price--number']}
                    >
                      {' '}
                      {getPriceInUsdc(+price)}
                    </span>{' '}
                    {currency ? currencyObj[currency].name : ''}
                  </span>
                </>
              ) : (
                '-'
              )}
            </div>
          </div>
        </div>
        <div className={classes['details']}>
          <ChildWithIndicator
            showIndicator={data?.diffs?.expiration && isAdminPage}
            onClick={() =>
              setMenu(
                <CourseChangesMenu
                  changes={changedFields()}
                  focusChange="expiration"
                />
              )
            }
            children={
              <div className={classes['details__title']}>Course Time Limit</div>
            }
          />
          <div className={classes['details__content']}>
            {expiration === COURSE_EXPIRY.INDEFINITELY
              ? 'Indefinitely'
              : `1 Year (Expiring on ${moment(
                  new Date(Date.parse(date_created)).setFullYear(
                    new Date(Date.parse(date_created)).getFullYear() + 1
                  )
                ).format(dateFormatUrl)})`}
          </div>
        </div>

        {isAdminPage &&
          !!data?.diffs &&
          changes !== COURSE_CHANGES.NO_CHANGES &&
          !!changedFields()?.length && (
            <InfoBox
              className={classes['details__updates-info-box']}
              type="info"
              title="Updates to review"
              msg={`There are ${
                changedFields().length
              } updates for you to review.`}
              button={{
                text: 'See all',
                variant: 'outline',
                onClick: () =>
                  setMenu(<CourseChangesMenu changes={changedFields()} />),
                minWidth: 'sm'
              }}
            />
          )}

        {(course_relationship === COURSE_RELATIONSHIP.CREATOR ||
          isAdminPage) && (
          <>
            {(isPublishing || isApproved) && !isAdminPage && (
              <InfoBox
                type="info"
                msg="Please note, that publishing a course may take up to a few hours depending on the amount of videos uploaded, their size and the network traffic."
              />
            )}
            {(isRejected || isTakedown || (isSubmitted && isAdminPage)) &&
              !!reject_reason && (
                <InfoBox
                  type="warning"
                  msg={
                    <div>
                      <div
                        className={`${classes['u-semiBold']} ${classes['u-m8-bot']}`}
                      >
                        Rejection Reason
                      </div>
                      <TextWithCharLimit text={reject_reason} limit={100} />
                    </div>
                  }
                />
              )}
          </>
        )}

        {isAdminPage && <AdminButtons course={data} />}

        {course_relationship === COURSE_RELATIONSHIP.CREATOR && (
          <CreatorButtons course={data} />
        )}

        {!isAdminPage &&
          !userData.isAdmin &&
          course_relationship !== COURSE_RELATIONSHIP.CREATOR && (
            <UserButtons course={data} />
          )}

        {!isTokenExpired && isConnected && !isCorrectChain && (
          <InfoBox
            type="warning"
            msg="Unsupported network."
            button={{
              text: 'Switch Network',
              variant: 'outline',
              icon: Switch,
              onClick: () =>
                !!switchNetwork && switchNetwork(+REACT_APP_CHAIN_ID)
            }}
          />
        )}
        {!isAdminPage &&
          !isPublic &&
          !isCreatorPublicView &&
          isPublished &&
          !isDraftOfPublished &&
          data.has_draft_copy && (
            <PublishedChangesMsg
              courseSlug={slug as string}
              withButton
              msg="Course has unpublished changes."
            />
          )}
      </div>
    </div>
  );
};

export default CourseView;
