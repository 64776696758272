import React, { useContext } from 'react';
import { ReactComponent as Warning } from 'assets/icons/warning.svg';
import { ReactComponent as PlusCircle } from 'assets/icons/plus-circle.svg';
import { appContext } from 'context/appContext';
import Divider from 'components/divider/Divider';
import Tooltip from 'components/tooltip/Tooltip';
import Player from 'components/player/Player';
import CourseRowCard from './CourseRowCard';
import TextWithCharLimit from 'components/textWithCharLimit/TextWithCharLimit';
import { ICourseBundleFormFields } from 'utils/yupSchemas';
import { formatPrice } from 'utils/format';
import classes from './Form.module.scss';

interface ISummary {
  data: ICourseBundleFormFields;
  // Intentionally set as any to prevent an error
  errors: any;
  setStep?: (step: number) => void;
}

const Summary = ({ data, errors, setStep }: ISummary) => {
  const {
    name,
    category,
    description,
    thumbnail,
    currency,
    price,
    discount,
    courses
  } = data;

  const mainInfo: {
    label: string;
    value: string | undefined;
    key: string;
  }[] = [
    { label: 'Bundle Name', value: name, key: 'name' },
    { label: 'Bundle Category', value: category?.label, key: 'category' },
    { label: 'Bundle Description', value: description, key: 'description' }
  ];

  const previewInfo = [
    { label: 'Bundle Thumbnail', value: thumbnail, key: 'thumbnail' }
  ];

  const coursesInfo = [{ label: 'Courses', value: courses, key: 'courseIds' }];

  const bundleCurrency = currency ? currency.label : '';
  const currencySymbol = currency ? currency.symbol : '';
  const bundlePrice = parseFloat(price.toString()) || 0;

  const priceInfo = [
    {
      label: 'Bundle Price',
      value: bundlePrice
        ? `${formatPrice(bundlePrice)} ${bundleCurrency}`
        : '-',
      key: 'price'
    }
  ];

  const renderFieldLabel = (key: string, label: string, step: number) => {
    if (!errors || !errors[key])
      return <div className={classes['row__title']}>{label}</div>;

    return (
      <div
        onClick={() => !!setStep && setStep(step)}
        className={`${classes['row__title']} ${classes['row__title--error']}`}
      >
        <Tooltip
          id={`tooltip-${label}`}
          text={`In order to proceed with bundle creation, please enter valid ${label}.`}
        >
          <span>
            {label}
            <Warning />
          </span>
        </Tooltip>
      </div>
    );
  };

  return (
    <div
      className={`${classes['summary-wrapper']} ${classes['summary-step']} ${classes['bundle-form-step']}`}
    >
      <h5 className={`${classes['u-h4']} ${classes['u-light']}`}>
        Main Information
      </h5>
      {mainInfo.map((field, i) => {
        return (
          <div key={`${field.label}-${i}`} className={classes['row']}>
            {renderFieldLabel(field.key, field.label, 0)}
            <div className={classes['row__desc']}>
              <TextWithCharLimit
                text={field.value || '-'}
                limit={10000}
                hideBtn
              />
            </div>
          </div>
        );
      })}
      <Divider dir="horizontal" />
      <h4 className={classes['u-light']}>Thumbnails & Preview</h4>
      <div className={`${classes['row']} ${classes['row--flex']}`}>
        {previewInfo.map((field, i) => {
          const isVideo = field.label === 'Course Video Preview';
          return (
            <div
              key={`${field.label}-${i}`}
              className={classes['row__img-wrapper']}
            >
              {renderFieldLabel(field.key, field.label, 1)}
              {field.value ? (
                isVideo ? (
                  <div className={classes['row__img']}>
                    <Player src={field.value} height="128px" hasControls />
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundImage: `url(${field.value})`
                    }}
                    className={classes['row__img']}
                  />
                )
              ) : (
                '-'
              )}
            </div>
          );
        })}
      </div>
      <Divider dir="horizontal" />
      <h4 className={classes['u-light']}>Courses in bundle</h4>
      {coursesInfo.map((field, i) => {
        return (
          <div
            key={`${field.label}-${i}`}
            className={classes['row__img-wrapper']}
          >
            {renderFieldLabel(field.key, field.label, 1)}
            {field.value ? (
              <div>
                {!!courses.length
                  ? courses.map((course, index) => (
                      <div className={classes['row-card']}>
                        {index > 0 && <PlusCircle />}
                        <CourseRowCard isLightBackground course={course} />
                      </div>
                    ))
                  : '-'}
              </div>
            ) : (
              '-'
            )}
          </div>
        );
      })}

      <Divider dir="horizontal" />
      <h4 className={classes['u-light']}>Price Summary</h4>
      {priceInfo.map((field, i) => {
        const isPrice = field.label === 'Bundle Price';
        return (
          <div key={`${field.label}-${i}`} className={classes['row']}>
            {renderFieldLabel(field.key, field.label, 2)}
            <div
              className={`${classes['row__desc']} ${
                isPrice ? classes['row__desc--flex'] : ''
              }`}
            >
              {isPrice && currencySymbol && (
                <img src={currencySymbol} alt="Symbol" width={24} height={24} />
              )}
              <div>{`${
                bundlePrice - (Number(discount) / 100) * bundlePrice
              } ${bundleCurrency}`}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Summary;
