import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { expirationOptions } from '../../courses/components/Step3';
import CourseBundleForm from '../components/CourseBundleForm';
import { windowContext } from 'context/windowsContext';
import { ICourseBundleFormFields } from 'utils/yupSchemas';
import classes from './CreateBundle.module.scss';

const formSteps = [
  'Select Courses',
  'Arrange Courses',
  'Name & Description',
  'Pricing & Time Limit',
  'Summary'
];

const CreateCourseBundle = () => {
  const { state: locationState } = useLocation();
  const [currentStep, setCurrentStep] = useState<number>(
    locationState?.savedFormData?.step || 0
  );
  const [finishedSteps, setFinishedSteps] = useState<number>(
    locationState?.savedFormData?.step || 0
  );
  const {
    windowSize: { isLgMobile }
  } = useContext(windowContext);

  // If the cached from data is a create form, use the cached data
  // if not, use the empty form
  // eslint-disable-next-line
  const [defaultFormData, setDefaultFormData] =
    useState<ICourseBundleFormFields>(
      locationState?.savedBundleFormData || {
        name: '',
        price: '',
        discount: 0,
        thumbnail: '',
        courses: [],
        description: '',
        courseIds: [],
        not_finished: false,
        category: null,
        expiration: [],
        currency: null
      }
    );

  return (
    <div id="course-form__wrapper" className={classes['wrapper']}>
      <div className={classes['container']}>
        {!isLgMobile && (
          <h3
            className={`${classes['u-semiBold']} ${classes['u-text--center']}`}
          >
            Create Bundle
          </h3>
        )}
        <CourseBundleForm
          steps={formSteps}
          finishedSteps={finishedSteps}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          setStep={(step: number) => {
            setCurrentStep(step);
            setFinishedSteps(step === 0 ? 0 : step);
          }}
          defaultFormData={defaultFormData}
        />
      </div>
    </div>
  );
};

export default CreateCourseBundle;
