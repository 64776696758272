import { useContext } from 'react';
import moment from 'moment';
import { ReactComponent as DndIcon } from 'assets/icons/drag-and-drop.svg';
import { ReactComponent as Image } from 'assets/icons/image.svg';
import TextWithCharLimit from 'components/textWithCharLimit/TextWithCharLimit';
import { ICourse } from 'query/course-module/dto';
import { userContext } from 'context/userContext';
import { windowContext } from 'context/windowsContext';
import { formatNames } from 'utils/format';
import { dateFormatUrl } from 'utils/static';
import { REACT_APP_FILES_EDU_PUBLIC_DOMAIN } from 'utils/constants';
import classes from './SelectedCourseCard.module.scss';

interface IHighlightedCourseCardProps {
  course: ICourse;
  size?: 'sm' | 'lg';
}

const SelectedCourseCard = ({ course, size }: IHighlightedCourseCardProps) => {
  const { userData } = useContext(userContext);
  const {
    windowSize: { isLgMobile }
  } = useContext(windowContext);
  const { thumbnail, name, price, video_count, expiration, currency } = course;

  return (
    <div className={`${classes['selected']} ${classes[`selected--${size}`]}`}>
      <div className={classes['selected__img-col']}>
        <DndIcon className={classes['dnd-icon']} />
        {!!thumbnail ? (
          <div
            className={`${classes['selected__img-col__thumbnail']} ${
              classes[`selected__img-col__thumbnail--${size}`]
            }`}
            style={{
              backgroundImage: `url(${
                REACT_APP_FILES_EDU_PUBLIC_DOMAIN + thumbnail
              })`,
              backgroundPosition: 'center'
            }}
          />
        ) : (
          <Image />
        )}
      </div>
      <div className={classes['selected__info-col']}>
        <div
          className={`${classes['u-semiBold']} ${classes['u-body1']} ${classes['course__name']}`}
        >
          <TextWithCharLimit
            text={name || '(Untitled Course)'}
            limit={15}
            hideBtn
          />
        </div>
        <div className={classes['u-body1']}>
          {formatNames(userData.first_name + ' ' + userData.last_name)}
        </div>
        <div className={classes['u-body2']}>
          {price + ' '}
          <b>{currency?.toLocaleUpperCase()}</b>
        </div>
        {!isLgMobile && (
          <>
            <div className={classes['u-body2']}>{video_count}</div>
            <div className={classes['u-body2']}>
              {moment(Date.now() + expiration).format(dateFormatUrl)}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

SelectedCourseCard.defaultProps = {
  size: 'lg'
};

export default SelectedCourseCard;
