import moment from 'moment';
import { ReactComponent as Image } from 'assets/icons/image.svg';
import TextWithCharLimit from 'components/textWithCharLimit/TextWithCharLimit';
import { dateFormatUrl } from 'utils/static';
import { REACT_APP_FILES_EDU_PUBLIC_DOMAIN } from 'utils/constants';
import { ICourse } from 'query/course-module/dto';
import classes from './CourseRowCard.module.scss';

interface ICourseRowCard {
  course: ICourse;
  hasTitleCharLimit?: boolean;
  imgSize: 'sm' | 'lg';
  isLightBackground?: boolean;
}

const CourseRowCard = ({
  course,
  imgSize,
  isLightBackground
}: ICourseRowCard) => {
  const { name, thumbnail, price, currency, date_created } = course;

  return (
    <div
      className={`${classes['container']} ${classes['u-semiBold']} ${
        classes[isLightBackground ? 'container__light' : '']
      }`}
    >
      {!!thumbnail ? (
        <img
          className={`${classes['thumb']} ${classes[`thumb--${imgSize}`]}`}
          src={REACT_APP_FILES_EDU_PUBLIC_DOMAIN + thumbnail}
          alt={name}
        />
      ) : (
        <Image />
      )}
      <div className={classes['course']}>
        <div className={`${classes['u-semiBold']} ${classes['u-body1']}`}>
          <TextWithCharLimit limit={25} text={name} hideBtn />
        </div>
        <div className={classes['u-body2']}>
          {price + ' '}
          <b>{currency?.toLocaleUpperCase()}</b>
        </div>
        <div className={classes['u-body2']}>
          {`Expires: ${moment(
            new Date(Date.parse(date_created)).setFullYear(
              new Date(Date.parse(date_created)).getFullYear() + 1
            )
          ).format(dateFormatUrl)}`}
        </div>
      </div>
    </div>
  );
};

CourseRowCard.defaultProps = {
  imgSize: 'lg',
  noBadge: false
};

export default CourseRowCard;
